import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface Root {
  loggedIn: boolean;
  access_token: string | null;
  refresh_token: string | null;
  role: string | null;
  profile: any
}

const initialState: Root = {
  loggedIn: false,
  access_token: null,
  refresh_token: null,
  role: null,
  profile: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_LOGIN: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    SET_ACCESS_TOKEN: (state, action: PayloadAction<string | null>) => {
      state.access_token = action.payload;
    },
    SET_REFRESH_TOKEN: (state, action: PayloadAction<string | null>) => {
      state.refresh_token = action.payload;
    },
    SET_ROLE: (state, action: PayloadAction<string | null>) => {
      state.role = action.payload;
    },
    SET_PROFILE: (state, action: PayloadAction<any>) => {
      state.profile = action.payload;
    },
  },
});

export const { SET_ACCESS_TOKEN, SET_REFRESH_TOKEN, SET_ROLE, SET_LOGIN, SET_PROFILE } =
  authSlice.actions;

export default authSlice.reducer;
