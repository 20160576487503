import logo from "./Logo Shapes 59.png";
import binoculars from "./black-binoculars-isolated-with-white-background 1.png";
import globe from "./Globe 1.png";
import greenTick from "./Group 3643.png";
import guy from "./smiling-mechanic-with-arms-crossed-spanner 1.png";

const image = {
  logo,
  binoculars,
  globe,
  greenTick,
  guy,
};

export default image;
