export const adminPermissions = [
  "Create Chairman",
  "Create Area Unit",
  "Create LGA",
  "Disable admin",
  "See all Chairman",
  "See Chairman Artisans",
  "See Transactions",
];
export const chairmanPermissions = [
  "Create Artisan",
  "Initiate Transfer",
  "See Transactions",
];

export const isSubscriptionExpired = (startDate: string) => {
  const currentDate = new Date(startDate); // The current date is the end date
  const startDateObj = new Date();

  // Check if the current date is after the start date
  return currentDate >= startDateObj;
};

export const isPasswordValid = (value: string | any[]) => {
  return value.length < 8 ? false : true;
};

export const isEmailValid = (value: string) => {
  return /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/i.test(value);
};

export const isPasswordSame = (oldPassword: any, newPassword: any) => {
  return oldPassword === newPassword ? true : false;
};

export const isEmpty = (value: string | any[]) => {
  return value.length === 0 ? false : true;
};

export const date = (value: string | number | Date) => {
  return new Date(value).toDateString();
};

let otpInput: any[] = [];

export const otpValue = (
  e: { preventDefault: () => void; target: any },
  inputs: any[]
) => {
  e.preventDefault();
  let element = e.target;

  if (/^[0-9]+$/.test(element.value)) {
    inputs.map((el: { current: { name: any } }, idx: number) => {
      if (el.current.name === element.name) {
        inputs[idx + 1]?.current.focus();
        otpInput.push(element.value);
      }
    });
  } else if (element.value.length === " ") {
    inputs.map((el: { current: { name: any } }, idx: number) => {
      if (el.current.name === element.name) {
        inputs[idx - 1]?.current.focus();
        otpInput.pop();
      }
    });
  }
  console.log(otpInput.join(""));
};

export const DelayFor = (fire: () => void, time = 1000) => {
  setTimeout(() => {
    fire();
  }, time);
};

export const capitalize = (word: string) => {
  return word[0]?.toUpperCase() + word?.substring(1).toLowerCase();
};
