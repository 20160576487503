import React from "react";
import { FiLogOut } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/icons/riv-logo.svg";
import { ROUTES } from "./routes";
import { useDispatch } from "react-redux";
import { signOut } from "../../../api/auth";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import { DelayFor } from "../../../utils/helpers";

import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  SET_ACCESS_TOKEN,
  SET_LOGIN,
  SET_PROFILE,
  SET_REFRESH_TOKEN,
  SET_ROLE,
} from "../../../store/authSlice";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

const MenuBar = ({ show }: { show?: boolean }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [dropdown, setDropdown] = React.useState<boolean>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const dispatch = useDispatch()
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div
      style={{ display: show ? "flex" : "none" }}
      className="w-[250px] lg:w-[20vw] hidden lg:flex h-full bg-white absolute top-0 border-r border-r-[#E9E9E9] border-dashed lg:relative flex-col justify-between z-50"
    >
      <div>
        <div className="w-full h-[100px] flex justify-center items-center">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="w-[88%] mx-auto h-auto">
          {ROUTES.map((item: any, idx: number) => (
            <div className="my-2"
            
            >
              {item?.subItems ? (
                <div onClick={()=> setDropdown(prev => !prev)} className={`${dropdown ? "max-h-max" : "h-10"} overflow-hidden`}>
                
                    <div >
                      <div
                        key={idx}
                        className={`bg-blue-200 ${
                          location.pathname.includes(item.path)
                          ? "bg-primary-300"
                          : "bg-transparent"
                        } flex px-4 py-2 items-center rounded-md cursor-pointer w-full hover:bg-primary-300/30`}              >
                        <item.icon
                        size={23}
                        color={
                          location.pathname.includes(item.path) ? "#FFFFFF" : "#656869"
                        }
                      />
                        <p
                          className="ml-3 font- text-[17px]"
                          style={{
                            color: location.pathname.includes(item.path)
                              ? "#FFFFFF"
                              : "#656869",
                          }}
                        >
                          {item.name}
                        </p>
                        </div>
                      </div>
                      <div>
                        <div className="">
                        {item.subItems.map((subItem: any, idy: number ) => (
                          <p 
                          className=" pl-10 cursor-pointer py-2 hover:bg-primary-300/20 my-2 rounded-md"   
                          onClick={() => navigate(subItem.path)}
                          > {subItem.name}
                          </p>
                        ))}
                        </div>
                      </div>
                  </div>) : 
                  <div
                  key={idx}
                  onClick={() => navigate(item.path)}
                  className={` ${
                    location.pathname.includes(item.path)
                      ? "bg-primary-300"
                      : "bg-transparent"
                  } flex px-4 py-2 items-center rounded-md cursor-pointer hover:bg-primary-300/30 `}
                  >
                  <item.icon
                    size={23}
                    color={
                      location.pathname.includes(item.path) ? "#FFFFFF" : "#656869"
                    }
                  />
                  <p
                    className="ml-3 font- text-[17px]"
                    style={{
                      color: location.pathname.includes(item.path)
                        ? "#FFFFFF"
                        : "#656869",
                    }}
                  >
                    {item.name}
                  </p>
              </div> }
              
            </div>
          ))}
        </div>
      </div>
      <div
        onClick={() => {
          dispatch(SET_LOADER(true));
          signOut(
            (response) => {
              dispatch(SET_LOADER(false));
              dispatch(SET_ACCESS_TOKEN(null));
              dispatch(SET_REFRESH_TOKEN(null));
              dispatch(SET_ROLE(null));
              dispatch(SET_LOGIN(false));
              dispatch(SET_PROFILE(null));
              dispatch(
                SET_MODAL({
                  isError: false,
                  message: response.message,
                  show: true,
                  title: "Success!",
                })
              );
              DelayFor(() => {
                dispatch(
                  SET_MODAL({
                    isError: false,
                    message: "",
                    show: false,
                    title: "",
                  })
                );
              }, 3000);
            },
            (error) => {
              dispatch(SET_LOADER(false));
              dispatch(
                SET_MODAL({
                  isError: true,
                  message: error,
                  show: true,
                  title: "Error!",
                })
              );
              DelayFor(() => {
                dispatch(
                  SET_MODAL({
                    isError: false,
                    message: error,
                    show: false,
                    title: "",
                  })
                );
              }, 3000);
            }
          );
        }}
        className={`w-[80%] mx-auto bg-transparent mb-3 flex px-5 py-3 items-center rounded-md cursor-pointer`}
      >
        <FiLogOut color={"#D84A4A"} />
        <p className="ml-3 font-Grotesk text-[17px] text-[#D84A4A]">Logout</p>
      </div>
    </div>
  );
};

export default MenuBar;
