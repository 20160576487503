import { Button } from "@mui/material";
import React from "react";
import { IoMdClose } from "react-icons/io";
import { FormInput } from "../../../components/formInput";
import { PayInfo, PayInfoShopOwner } from "../../../config/formikServices";
import ConfirmationModal from "./ConfirmPaymentModal";

const PaymentCard = ({
  payOpen,
  setPayOpen,
  name
}: {
  payOpen: boolean;
  setPayOpen: React.Dispatch<React.SetStateAction<boolean>>;
  name: string
}) => {

  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const [duration, setDuration] = React.useState<string | null>(null);  
  const formik = PayInfoShopOwner(setOpenModal, setDuration );
  return (
    <div
      className="absolute top-0 left-0 w-full h-full bg-[#1C1A1AB2] flex justify-center items-center z-1100"
      style={{ display: payOpen ? "flex" : "none" }}
    >
      <div className="h-[90%] w-[90%] sm:h-auto md:w-auto md:h-[80%] lg:w-[50%] lg:h-auto bg-white rounded-lg py-3 px-3">
        <div className="w-full flex justify-end items-center">
          <div
            className="cursor-pointer p-2 rounded-full bg-[#EDECEC]"
            onClick={() => setPayOpen(false)}
          >
            <IoMdClose color="#2C3131" size={25} />
          </div>
        </div>
        <div className="flex h-full px-5 flex-col justify-center items-center">
          <h2 className="font-Grotesk text-[25px] text-primary font-bold mb-6">
            Subscription Duration
          </h2>
          <form
            className="min-w-[250px] !w-full !max-w-[450px]"
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <FormInput
              name="duration"
              label="Enter Duration (Months)"
              placeholder="Enter Duration in Months"
              formik={formik}
              type="text"
              color="dark"
            />
            <Button fullWidth variant="contained" type="submit">
              Pay Now
            </Button>
          </form>
        </div>
      </div>
      <ConfirmationModal openModal={openModal} setOpenModal={setOpenModal} duration={duration} name={name}/>
    </div>
  );
};

export default PaymentCard;
