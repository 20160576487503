import { MdOutlineDashboard } from "react-icons/md";
import { RiFileList2Line } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";

export const ROUTES = [
  {
    name: "Dashboard",
    path: "/chairman/dashboard",
    icon: MdOutlineDashboard,
  },
  {
    name: "History",
    path: "/chairman/history",
    icon: RiFileList2Line,
    subItems: [
      {
        name: "Pending Payments",
        path: "/chairman/pending-payments",
      },
      {
        name: "Total Payments",
        path: "/chairman/total-payments",
      }
    ]
  },
  {
    name: "My Settings",
    path: "/chairman/settings",
    icon: FiSettings,
  },
];
