import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { GoArrowUpRight, GoArrowDownLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { capitalize } from "../../../utils/helpers";

const RecentTransaction = ({ data }: { data: any }) => {
  const [dashboard, setDashboard] = React.useState<any>(null);

  React.useEffect(() => {
    setDashboard(data);
    // eslint-disable-next-line
  }, [data]);

  const navigate = useNavigate();
  return (
    <div className="w-full py-4 rounded-lg mb-2 h-full bg-white">
      <div className="w-full flex justify-between items-center px-6 mb-2">
        <h2 className=" font-Grotesk font-medium text-[20px] text-black ">
          Recent Transactions
        </h2>
        <p
          onClick={() => navigate("/admin/transactions")}
          className="text-primary-300 cursor-pointer"
        >
          View All
        </p>
      </div>
      <div className="overflow-auto w-full max-w-full h-[450px]">
        <table className="table-auto min-w-[210%] lg:min-w-full">
          <thead className="sticky top-0">
            <tr className="bg-[#F4F6F8]">
              <th
                scope="col"
                className="pl-6 pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]"
              >
                Description
              </th>
              <th className="pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]">
                Date
              </th>
              <th className="pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]">
                Chairman
              </th>
              <th className="pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]">
                LGA
              </th>
              <th className="pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]">
                Area Unit
              </th>
              <th className="pt-3 pb-2 text-center font-Grotesk font-normal text-[#626262]">
                Time
              </th>
              <th className="px-3 pt-3 pb-2 text-center font-Grotesk font-normal text-[#626262]">
                Amount
              </th>
            </tr>
          </thead>
          <tbody className="border-b border-b-[rgba(145,158,171,0.24)] w-full">
            {dashboard?.transactions.map((item: any) => (
              <tr key={item._id}>
                <th
                  scope="col"
                  className="py-3 text-left font-Grotesk font-normal text-black"
                >
                  <div className="flex items-center ml-6">
                    <div
                      className={`p-2 ${
                        item.type === "debit"
                          ? "bg-[#E25C5C1A]"
                          : "bg-[#DDF6DE]"
                      } rounded-full`}
                    >
                      {item.type === "debit" ? (
                        <GoArrowUpRight color={"#E25C5C"} size={20} />
                      ) : (
                        <GoArrowDownLeft color={"#53D258"} size={20} />
                      )}
                    </div>
                    <div className="ml-2">
                      <p className="font-medium text-black">
                        {capitalize(item.type)} Wallet
                      </p>
                    </div>
                  </div>
                </th>
                <th className="py-5 text-left font-Grotesk font-normal text-black">
                  {moment(item.created_at).format("DD MMM YYYY")}
                </th>
                <th className="py-5 text-left font-Grotesk font-normal text-black">
                  {item?.meta?.customer?.fullName || "N/A"}
                </th>
                <th className="py-5 text-left font-Grotesk font-normal text-black">
                  {item?.area_unit?.lga?.name || "N/A"}
                </th>
                <th className="py-5 text-left font-Grotesk font-normal text-black">
                  {item?.area_unit?.name || "N/A"}
                </th>
                <th className="py-5 text-center font-Grotesk font-normal text-[#637381]">
                  {moment(item.created_at).format("hh:mm A")}
                </th>
                <th
                  className={`py-5 text-center font-Grotesk font-normal ${
                    item.type === "debit" ? "text-[#E25C5C]" : "text-[#53D258]"
                  }`}
                >
                  ₦{item.amount.toLocaleString()}
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecentTransaction;
