import React from "react";
import { GoArrowUpRight, GoArrowDownLeft } from "react-icons/go";
import { Button, Pagination, PaginationItem } from "@mui/material";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import FundCard from "./fundCard";
import { useDispatch } from "react-redux";
// import { RootState } from "../../../store";
import { getBalance } from "../../../api/wallet";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import { DelayFor, capitalize } from "../../../utils/helpers";
import { getAreaUnitTransactions } from "../../../api/transactions";
import moment from "moment";
import ShopOwnerDashboardLayout from "../layout/shopOwnerDashboardLayout";

const ShopOwnerWallet = () => {
  const dispatch = useDispatch();
  const [balance, setBalance] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(1);
  const [transaction, setTransaction] = React.useState<any>(null);
  const [fundOpen, setFundOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getBalance(
      (response) => {
        dispatch(SET_LOADER(false));
        setBalance(response.data?.balance || 0);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  }, []);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getAreaUnitTransactions(
      { page },
      (response) => {
        dispatch(SET_LOADER(false));
        setTransaction(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  }, [page]);


  return (
    <ShopOwnerDashboardLayout className={`px-6 py-5 relative h-full`}>
      <div className="w-full flex justify-end mb-4">
        <Button onClick={() => setFundOpen(true)} variant="contained">
          Fund Wallet
        </Button>
      </div>
      <div className="w-full flex flex-row justify-between items-center flex-wrap">
        <div
          className="w-full bg-primary-300 sm:w-[48%] lg:w-[23%]  py-4 px-7 rounded-lg mb-4"
          // style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
        >
          <h3 className="text-grey font-DMSans text-[15px] text-white">
            Available Balance
          </h3>
          <h3 className="text-primary font-DMSans font-extrabold text-white text-[30px] mb-3">
            ₦{balance.toLocaleString()}
          </h3>
        </div>
      </div>
      <div className="w-full max-w-[100%]">
        <div className="w-full py-4 rounded-lg mb-2 h-full bg-white">
          <h2 className="px-6 font-Grotesk font-medium text-[20px] text-black mb-2">
            All Transactions
          </h2>
          <div className="overflow-auto w-full max-w-full">
            <table className="table-auto min-w-[210%] lg:min-w-full">
              <thead className="sticky top-0">
                <tr className="bg-[#F4F6F8]">
                  <th
                    scope="col"
                    className="pl-6 pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]"
                  >
                    Description
                  </th>
                  <th className="pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]">
                    Date
                  </th>
                  <th className="pt-3 pb-2 text-center font-Grotesk font-normal text-[#626262]">
                    Time
                  </th>
                  <th className="pt-3 pb-2 text-center font-Grotesk font-normal text-[#626262]">
                    Status
                  </th>
                  <th className="px-3 pt-3 pb-2 text-center font-Grotesk font-normal text-[#626262]">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody className="border-b border-b-[rgba(145,158,171,0.24)] w-full">
                {transaction?.docs.map((item: any) => (
                  <tr key={item._id}>
                    <th
                      scope="col"
                      className="py-3 text-left font- font-normal text-black"
                    >
                      <div className="flex items-center ml-6">
                        <div
                          className={`p-2 ${
                            item.type === "debit"
                              ? "bg-[#E25C5C1A]"
                              : "bg-[#DDF6DE]"
                          } rounded-full`}
                        >
                          {item.type === "debit" ? (
                            <GoArrowUpRight color={"#E25C5C"} size={20} />
                          ) : (
                            <GoArrowDownLeft color={"#53D258"} size={20} />
                          )}
                        </div>
                        <div className="ml-2">
                          <p className="font-medium text-black">
                            {capitalize(item.type)} Wallet
                          </p>
                        </div>
                      </div>
                    </th>
                    <th className="py-5 text-left font-Grotesk font-normal text-black">
                      {moment(item.created_at).format("DD MMM YYYY")}
                    </th>
                    <th className="py-5 text-center font-Grotesk font-normal text-[#637381]">
                      {moment(item.created_at).format("hh:mm A")}
                    </th>
                    <th className="py-5 text-center font-Grotesk text-black font-medium">
                      {capitalize(item.status)}
                    </th>
                    <th
                      className={`py-5 text-center font-Grotesk font-normal ${
                        item.type === "debit"
                          ? "text-[#E25C5C]"
                          : "text-[#53D258]"
                      }`}
                    >
                      ₦{item.amount.toLocaleString()}
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="w-full px-6 flex justify-end items-end mt-3 ">
            <Pagination
              count={transaction?.totalPages || 1}
              page={page}
              // color="secondary"
              onChange={(event, value: number) => setPage(value)}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: HiArrowLongLeft,
                    next: HiArrowLongRight,
                  }}
                  {...item}
                />
              )}
            />
          </div>
        </div>
      </div>
      <FundCard fundOpen={fundOpen} setFundOpen={setFundOpen} />
    </ShopOwnerDashboardLayout>
  );
};

export default ShopOwnerWallet;
