import React from "react";
import Timer, { ExpiredNotice } from "./timer";
import { capitalize } from "../utils/helpers";

const ShopOwnerCard = ({
  detail,
  showExpiry = true,
}: {
  detail: any;
  showExpiry?: boolean;
}) => {
  return (
    <div className="w-full py-2 px-2 bg-white rounded">
      <img
        src={
          detail?.owner?.image?.url ||
          "https://images.unsplash.com/photo-1581578017306-7334b15283df?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGdhcmRlbmluZ3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60"
        }
        alt=""
        className="w-full h-[130px] object-cover mb-4"
      />
      <div className="flex flex-col justify-between items-center">
        <p className="font-Semibold text-xl">
          {capitalize(detail?.owner?.first_name || "")}{" "}
          {capitalize(detail?.owner?.last_name || "")}
        </p>
        <p className="font-medium text-lg text-primary-300">
          {detail?.name || ""}
        </p>
        {/* {showExpiry && (
          <>
            {detail.sub_exipry_date ? (
              <Timer targetDate={detail.sub_exipry_date} />
            ) : (
              <ExpiredNotice />
            )}
          </>
        )} */}
      </div>
    </div>
  );
};

export default ShopOwnerCard;
