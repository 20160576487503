import React, { useContext } from "react";
import Box from "@mui/material/Box";
import AModal from "@mui/material/Modal";
import { useSelector, useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import { subscribeArtisan } from "../../../api/wallet";
import { DelayFor } from "../../../utils/helpers";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: 250,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  boxShadow: 0,
  m: "0 auto",
  p: 1,
};

const ConfirmationModal = ({
  openModal, setOpenModal, duration, name
}: {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  duration: string | null
  name: string
}) => {

  const { id } = useParams();

  // const {profile} = useSelector((state: RootState) => state.auth)
  // const {artisan} = useSelector((state: RootState) =>  state.artisan)
  const dispatch = useDispatch();

  const handleClose = () =>{
    setOpenModal(false)
  }
  const handlePayment = () => {
    
    dispatch(SET_LOADER(true));
    subscribeArtisan(
      { duration: Number(duration), user_id: id || "" },
      (response) => {
        // resetForm({ values: { duration: "" } });
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: false,
            message: response.message,
            show: true,
            title: "Success!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: "",
              show: false,
              title: "",
            })
          );
        }, 3000);
        handleClose()
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
        // handleClose()

      }
    );
    
  }

  return (
    <AModal
    open={openModal}
    onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="AModal p-3  justify-center items-center">
        <div className="text-right">        
          <span className="cursor-pointer inline-block" onClick={handleClose}><IoMdClose className="text-xl text-gray-300 hover:text-gray-500"/></span>
        </div>
        
        <div className="flex justify-between items-center py-2">
          <div>
        <p className="text-xl text-gray-600 font-semibold text-center ">Confirm Subscription</p>

      
        <p className="text-gray-950 text-base font-medium border-t border-b border-gray-200 py-4 text-center">You are about to make <span className="font-semibold text-gray-900">{duration && duration }</span>  {duration && parseInt(duration) > 1 ? "months" : "month"} subscription for <span className="font-semibold text-gray-900 capitalize">{name}</span> </p>
        <div className="text-right py-3 mt-4">
        <button className="bg-primary-300 hover:bg-primary-200 py-1 px-2 rounded text-gray-50 text-base hover:text-gray-500 font-semibold mx-2" onClick={handlePayment}>Confirm</button>
        <button className="bg-gray-300 hover:bg-gray-200 py-1 px-2 rounded text-gray-900 text-base hover:text-gray-600 font-semibold" onClick={handleClose}>Cancel</button>
        </div>
        </div>
        </div>
      </Box>
    </AModal>
  );
};

export default ConfirmationModal;
