import axios from "axios";
import { store } from "../store";
import { refreshToken } from "./auth";
import { BASE_URL } from "./config";

export const getAllTransactions = async (
  data: {
    page: number;
    start_date: string | null;
    end_date: string | null;
    lga: string | null;
    type: string | null;
    area_unit: string | null;
  },
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${BASE_URL}/wallet/transactions?page=${data.page}${
      data.start_date ? `&start_date=${data.start_date}` : ""
    }${data.end_date ? `&end_date=${data.end_date}` : ""}${
      data.type ? `&type=${data.type}` : ""
    }${data.area_unit ? `&area_unit=${data.area_unit}` : ""}${
      data.lga ? `&lga=${data.lga}` : ""
    }`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.access_token}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    if (err?.response?.data.status === 401) {
      refreshToken(
        async (arg) => {
          const updatedConfig = {
            ...config,
            headers: { Authorization: `Bearer ${arg.data.access_token}` },
          };
          const response = await axios(updatedConfig);
          execute(response.data);
        },
        (errorNew) => {
          error(errorNew);
        }
      );
    } else {
      err?.response?.data?.message &&
        error(
          err?.response?.data?.message || "Something went wrong. Try again."
        );
    }
  }
};

export const getAreaUnitTransactions = async (
  data: {
    page: number;
    // area_unit: string;
  },
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${BASE_URL}/wallet/transactions/area-unit?page=${data.page}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.access_token}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    if (err?.response?.data.status === 401) {
      refreshToken(
        async (arg) => {
          const updatedConfig = {
            ...config,
            headers: { Authorization: `Bearer ${arg.data.access_token}` },
          };
          const response = await axios(updatedConfig);
          execute(response.data);
        },
        (errorNew) => {
          error(errorNew);
        }
      );
    } else {
      err?.response?.data?.message &&
        error(
          err?.response?.data?.message || "Something went wrong. Try again."
        );
    }
  }
};
