import { MdOutlineDashboard } from "react-icons/md";
import { RiFileList2Line, RiGovernmentLine } from "react-icons/ri";
import { FiSettings, FiUsers } from "react-icons/fi";
import { TbCurrentLocation } from "react-icons/tb";
import { BsPersonWorkspace } from "react-icons/bs";

export const ROUTES = [
  {
    name: "Dashboard",
    path: "/admin/dashboard",
    icon: MdOutlineDashboard,
  },
  {
    name: "Manage User",
    path: "/admin/user",
    icon: FiUsers,
  },
  {
    name: "Manage LGA",
    path: "/admin/lga",
    icon: RiGovernmentLine,
  },
  {
    name: "Manage Services",
    path: "/admin/services",
    icon: BsPersonWorkspace,
  },
  {
    name: "Area Unit",
    path: "/admin/area-unit",
    icon: TbCurrentLocation,
  },
  {
    name: "Transactions",
    path: "/admin/transactions",
    icon: RiFileList2Line,
  },
  {
    name: "My Settings",
    path: "/admin/settings",
    icon: FiSettings,
  },
];
