import React from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

export const FormInput = ({
  name,
  label,
  placeholder,
  formik,
  type,
  color = "dark",
  naira = false,
  disable = false,
  className,
}: {
  name: string;
  label: string;
  placeholder: string;
  formik: any;
  type: React.HTMLInputTypeAttribute;
  color?: string;
  naira?: boolean;
  disable?: boolean;
  className?: string;
}) => {
  let [password, setPassword] = React.useState("password");
  let [seePassword, setSeePassword] = React.useState(false);

  return (
    <div className="relative w-full my-3">
      <span
        className={`${
          color === "light" ? "text-white" : "text-primary-400"
        } text-sm`}
      >
        {label}
      </span>
      <div
        className={`flex justify-between bg-grey-100 items-center rounded-md border-none ${
          formik.touched[name] && formik.errors[name]
            ? "border-error"
            : "border-[#cacaca]"
        } py-2 px-3 my-1 text-sm`}
      >
        {naira && <p className="mr-1">₦ </p>}
        <input
          type={type === "password" ? password : type}
          name={name}
          placeholder={placeholder}
          className={`w-full text-sm bg-grey-100 placeholder:text-grey-50 placeholder:font-light py-2 focus:outline-none ${className}`}
          {...formik.getFieldProps(name)}
          disabled={disable}
        />
        {type === "password" && (
          <div
            onClick={() => {
              setPassword((state) => {
                if (state === "password") {
                  return "text";
                } else {
                  return "password";
                }
              });
              setSeePassword((state) => !state);
            }}
          >
            {!seePassword ? (
              <FaRegEye
                style={{
                  color: "#78797A",
                  cursor: "pointer",
                }}
              />
            ) : (
              <FaRegEyeSlash
                style={{
                  color: "#78797A",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        )}
      </div>
      {formik.touched[name] && formik.errors[name] ? (
        <p className={`text-error text-xs px-2 py-0 bg-transparent`}>
          {formik.errors[name]}
        </p>
      ) : null}
    </div>
  );
};

export function FormInputWithNoFormik({
  name,
  label,
  placeholder,
  type,
  color = "dark",
  naira = false,
  disable = false,
}: {
  name: string;
  label: string;
  placeholder: string;
  type: React.HTMLInputTypeAttribute;
  color?: string;
  naira?: boolean;
  disable?: boolean;
}) {
  return (
    <div className="relative w-full">
      <span className={`${color === "light" && "text-white"} text-sm`}>
        {label}
      </span>
      <div
        className={`flex justify-between bg-grey-100 items-center rounded-lg border-none border-[#cacaca] py-2 px-3  text-sm`}
      >
        {naira && <p className="mr-1">₦ </p>}
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          className="w-full bg-grey-100 text-sm py-2"
          disabled={disable}
        />
      </div>
    </div>
  );
}
