import axios from "axios";
import { BASE_URL } from "./config";

export const getPermissions = async (
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${BASE_URL}/permissions`,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    err?.response?.data?.message &&
      error(err?.response?.data?.message || "Something went wrong. Try again.");
  }
};

export const getPermissionsByRole = async (
  role: string,
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${BASE_URL}/permissions/${role}`,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    err?.response?.data?.message &&
      error(err?.response?.data?.message || "Something went wrong. Try again.");
  }
};
