import React from "react";
import DashboardLayout from "../layout/shopOwnerDashboardLayout";
import ArtisanCard from "../../../components/artisanCard";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getArtisans, getShopOwners } from "../../../api/user";
import { RootState } from "../../../store";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import { DelayFor } from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import { Pagination, PaginationItem } from "@mui/material";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";

const Artisans = () => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(1);
  const [artisans, setArtisans] = React.useState<any>(null);
  const { profile } = useSelector((state: RootState) => state.auth);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    if (profile) {
      getArtisans(
        { page, shop: profile?.shop?._id },
        (response) => {
          dispatch(SET_LOADER(false));
          setArtisans(response.data);
        },
        (error) => {
          dispatch(SET_LOADER(false));
          dispatch(
            SET_MODAL({
              isError: true,
              message: error,
              show: true,
              title: "Error!",
            })
          );
          DelayFor(() => {
            dispatch(
              SET_MODAL({
                isError: false,
                message: error,
                show: false,
                title: "",
              })
            );
          }, 3000);
        }
      );
    }
  }, [profile, page]);
  return (
    <DashboardLayout>
      <div className="flex justify-between flex-row flex-wrap mt-10 mx-6">
        {artisans?.docs.map((item: any) => (
          <div key={item._id} className="mb-5 w-[48%] md:w-[32%] lg:w-[24%]">
            <Link to={`/shop-owner/dashboard/artisans/${item._id}`}>
              <ArtisanCard detail={item} />
            </Link>
          </div>
        ))}
        <div className="w-full px-6 flex justify-end items-end mt-3 ">
          <Pagination
            count={artisans?.totalPages || 0}
            page={page}
            // color="secondary"
            onChange={(event, value: number) => setPage(value)}
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  previous: HiArrowLongLeft,
                  next: HiArrowLongRight,
                }}
                {...item}
              />
            )}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Artisans;
