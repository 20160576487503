import React from "react";
import Nav from "../components/nav";
import image from "../assets/imgs/image";
import LandingSearch from "../components/landingSearch";
import { useDispatch } from "react-redux";
import { getAllLGA } from "../api/lga";
import { SET_LOADER, SET_MODAL } from "../store/appSlice";
import { DelayFor } from "../utils/helpers";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [lga, setLGA] = React.useState<any[]>([]);

  const getLGA = () => {
    dispatch(SET_LOADER(true));
    getAllLGA(
      (response) => {
        dispatch(SET_LOADER(false));
        const data = response.data.map((item: any) => ({
          label: item.name,
          value: item._id,
        }));
        setLGA(data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  };

  React.useEffect(() => {
    getLGA();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-white">
      <Nav />
      <div className="bg-primary-100 items-center relative h-90vh">
        <img
          src={image.globe}
          alt=""
          className="h-full absolute right-0 opacity-25"
        />
        <div className="mb-5 w-11/12 mx-auto h-full flex items-center text-center relative z-30 ">
          <div className="w-full">
            <h1 className="font-black text-primary-300 text-3xl mb-10 uppercase">
              Nasarawa State Artisans
            </h1>
            <form action="">
              <div className="w-full lg:w-3/5 2xl:w-1/2 mx-auto">
                <LandingSearch
                  name="lga"
                  data={lga}
                  onChange={(e) => navigate(`/result/${e.target.value}`)}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
