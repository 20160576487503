import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import appReducer from "./appSlice";
import addUserReducer from "./addUserSlice";
import artisanReducer from "./artisanSlice";
import shopOwnerReducer from "./shopOwnerSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const reducers = combineReducers({
  auth: authReducer,
  artisan: artisanReducer,
  app: appReducer,
  shop_owner: shopOwnerReducer,
  addUser: addUserReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
