import React from "react";
import AModal from "@mui/material/Modal";
import { AiOutlineClose } from "react-icons/ai";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { FormInput } from "../../../components/formInput";
import { UserInfo } from "../../../config/formikServices";
import SelectInput from "../../../components/selectInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { SET_PERMISSION } from "../../../store/addUserSlice";
import { useDispatch } from "react-redux";
import {
  DelayFor,
  adminPermissions,
  chairmanPermissions,
} from "../../../utils/helpers";
import { getAreaUnitByLGA } from "../../../api/area_unit";
import { getAllLGA } from "../../../api/lga";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import { getPermissionsByRole } from "../../../api/permission";
import { updateUser } from "../../../api/user";

const steps = ["User Profile", "Permissions"];

const UserProfile = ({
  handleNext,
  details,
}: {
  details: any;
  handleNext: () => void;
}) => {
  const dispatch = useDispatch();
  const formik = UserInfo(() => {
    dispatch(SET_PERMISSION(details?.permissions));
    handleNext();
  });
  const [lga, setLga] = React.useState<any[]>([]);
  const [area_unit, setAreaUnit] = React.useState<any[]>([]);

  React.useEffect(() => {
    formik.setValues({
      firstName: details?.first_name || "",
      lastName: details?.last_name || "",
      gender: details?.gender || "",
      role: details?.type || "",
      areaUnit: details?.area_unit?._id || "",
      lga: details?.area_unit?.lga?._id || "",
      phoneNumber: details?.phone_number || "",
    });
  }, [details]);

  React.useEffect(() => {
    if (formik.values.role === "chairman") {
      dispatch(SET_LOADER(true));
      getAllLGA(
        (response) => {
          dispatch(SET_LOADER(false));
          setLga(response.data);
        },
        (error) => {
          dispatch(SET_LOADER(false));
          dispatch(
            SET_MODAL({
              isError: true,
              message: error,
              show: true,
              title: "Error!",
            })
          );
          DelayFor(() => {
            dispatch(
              SET_MODAL({
                isError: false,
                message: error,
                show: false,
                title: "",
              })
            );
          }, 3000);
        }
      );
    }
  }, [formik.values.role]);

  React.useEffect(() => {
    if (formik.values.lga && formik.values.lga.length !== 0) {
      dispatch(SET_LOADER(true));
      getAreaUnitByLGA(
        formik.values.lga,
        (response) => {
          dispatch(SET_LOADER(false));
          setAreaUnit(response.data);
        },
        (error) => {
          dispatch(SET_LOADER(false));
          dispatch(
            SET_MODAL({
              isError: true,
              message: error,
              show: true,
              title: "Error!",
            })
          );
          DelayFor(() => {
            dispatch(
              SET_MODAL({
                isError: false,
                message: error,
                show: false,
                title: "",
              })
            );
          }, 3000);
        }
      );
    }
  }, [formik.values.lga]);
  return (
    <div className="px-3 w-full mt-5 max-h-[300px] overflow-auto">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <FormInput
          name="firstName"
          label="First Name"
          placeholder="enter your first name name here"
          formik={formik}
          type="text"
          color="dark"
        />
        <FormInput
          name="lastName"
          label="Last Name"
          placeholder="enter your last name name here"
          formik={formik}
          type="text"
          color="dark"
        />
        <FormInput
          name="phoneNumber"
          label="Phone Number"
          placeholder="0000 0000 0000"
          formik={formik}
          type="text"
          color="dark"
        />
        <SelectInput
          label="Role"
          name="role"
          formik={formik}
          data={[
            { id: "admin", name: "admin" },
            { id: "chairman", name: "chairman" },
          ]}
          placeholder="Select a Role"
        />
        {formik.values.role === "chairman" && (
          <SelectInput
            label="LGA"
            name="lga"
            formik={formik}
            data={lga.map((item) => ({ id: item._id, name: item.name }))}
            placeholder="Select an LGA"
          />
        )}
        {formik.values.lga !== "" && formik.values.role === "chairman" && (
          <SelectInput
            label="Area Unit"
            name="areaUnit"
            formik={formik}
            data={area_unit.map((item) => ({ id: item._id, name: item.name }))}
            placeholder="Select an area unit"
          />
        )}
        <Button variant="contained" type="submit" fullWidth>
          Next
        </Button>
      </form>
    </div>
  );
};

const Permissions = ({
  handleNext,
  details,
  setUsers,
  handlePrev,
}: {
  setUsers: React.Dispatch<any>;
  details: any;
  handleNext: () => void;
  handlePrev: () => void;
}) => {
  const dispatch = useDispatch();
  const { user, permissions } = useSelector(
    (state: RootState) => state.addUser
  );
  const [allPermissions, setAllPermissions] = React.useState<any[]>([]);
  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getPermissionsByRole(
      user.role,
      (response) => {
        dispatch(SET_LOADER(false));
        setAllPermissions(response.data);
      },
      (error) => {
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  }, [user.role]);

  return (
    <div className="px-3 w-full mt-5 max-h-[300px] overflow-auto">
      <FormGroup row>
        {allPermissions.map((item, idx) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.includes(item.key?.toLowerCase())}
                onChange={(e) =>
                  dispatch(
                    SET_PERMISSION(
                      permissions.includes(item.key.toLowerCase())
                        ? permissions.filter(
                            (key: any) => key !== item.key.toLowerCase()
                          )
                        : [...permissions, item.key.toLowerCase()]
                    )
                  )
                }
              />
            }
            label={item.name}
          />
        ))}
      </FormGroup>

      <div className="w-full justify-between flex items-center my-5">
        <Button variant="outlined" onClick={handlePrev} sx={{ width: "47%" }}>
          Previous
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(SET_LOADER(true));
            updateUser(
              details?._id,
              user.role === "chairman"
                ? {
                    first_name: user.firstName,
                    last_name: user.lastName,
                    phone_number: user.phoneNumber,
                    gender: user.gender,
                    area_unit: user.areaUnit,
                    lga: user.lga,
                    permissions,
                  }
                : {
                    first_name: user.firstName,
                    last_name: user.lastName,
                    phone_number: user.phoneNumber,
                    gender: user.gender,
                    permissions,
                  },
              (response) => {
                handleNext();
                console.log(response.data)
                setUsers((prev: any) => {
                  const newData = prev.docs.map((item: any) =>
                    item._id === response.data._id ? response.data : item
                  );
                  return {
                    ...prev,
                    docs: newData,
                  };
                });
                dispatch(SET_LOADER(false));
                dispatch(
                  SET_MODAL({
                    isError: false,
                    message: response.message,
                    show: true,
                    title: "Success!",
                  })
                );
                DelayFor(() => {
                  dispatch(
                    SET_MODAL({
                      isError: false,
                      message: "",
                      show: false,
                      title: "",
                    })
                  );
                }, 3000);
              },
              (error) => {
                dispatch(SET_LOADER(false));
                dispatch(
                  SET_MODAL({
                    isError: true,
                    message: error,
                    show: true,
                    title: "Error!",
                  })
                );
                DelayFor(() => {
                  dispatch(
                    SET_MODAL({
                      isError: false,
                      message: error,
                      show: false,
                      title: "",
                    })
                  );
                }, 3000);
              }
            );
          }}
          type="submit"
          sx={{ width: "47%" }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const EditUser = ({
  show,
  details,
  setUsers,
  hide,
}: {
  details: any;
  setUsers: React.Dispatch<any>;
  show: boolean;
  hide: () => void;
}) => {
  const [activeStep, setActiveStep] = React.useState<number>(0);
  return (
    <AModal
      open={show}
      onClose={() => {
        setActiveStep(0);
        hide();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <div className="w-[90%] sm:w-[80%] md:w-[60%] lg:w-[50%] max-h-full bg-white px-3 py-4 rounded-lg">
        <div className="w-full flex justify-end items-center mb-3">
          <AiOutlineClose
            size={23}
            className="text-primary-300 cursor-pointer"
            onClick={() => {
              setActiveStep(0);
              hide();
            }}
          />
        </div>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, idx) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 ? (
          <UserProfile
            details={details}
            handleNext={() =>
              setActiveStep(
                activeStep === steps.length - 1 ? activeStep : activeStep + 1
              )
            }
          />
        ) : activeStep === 1 ? (
          <Permissions
            details={details}
            setUsers={setUsers}
            handleNext={() => {
              setActiveStep(0);
              hide();
            }}
            handlePrev={() => setActiveStep(activeStep - 1)}
          />
        ) : null}
      </div>
    </AModal>
  );
};

export default EditUser;
