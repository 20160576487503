export function SelectInput({
  label,
  data,
  name,
  color = "dark",
  formik,
  placeholder,
}: {
  label: string;
  data: any[];
  name: string;
  color?: string;
  formik: any;
  placeholder: string;
}) {
  return (
    <div className="relative w-full mb-5">
      <span className={`${color === "light" && "text-white"} text-sm`}>
        {label}
      </span>
      <div
        className={`flex justify-between bg-transparent items-center rounded-lg border border-solid ${
          formik.touched[name] && formik.errors[name]
            ? "border-error"
            : "border-[#cacaca]"
        } py-[10px] px-3  text-sm`}
      >
        <select
          name={name}
          value={data?.find((item) => formik.values[name] === item.name)?.name}
          {...formik.getFieldProps(name)}
          className="w-full bg-transparent outline-none"
        >
          <option value="" selected disabled>
            {placeholder || label}
          </option>
          {data?.map((data, idx) => (
            <option value={data.id} key={idx}>
              {data.name}
            </option>
          ))}
        </select>
      </div>
      {formik.touched[name] && formik.errors[name] ? (
        <p className={`text-error text-xs px-2 py-0 bg-transparent mt-3`}>
          {formik.errors[name]}
        </p>
      ) : null}
    </div>
  );
}

export default SelectInput;
