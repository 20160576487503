import axios from "axios";
import { refreshToken } from "./auth";
import { BASE_URL } from "./config";
import { store } from "../store";

export const getAllLGA = async (
  execute: (e: any) => void,
  error: (e: string) => void,
  data?: {
    name: string;
  }
) => {
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${BASE_URL}/lga${data?.name ? `?name=${data.name}` : ""}`,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    err?.response?.data?.message &&
      error(err?.response?.data?.message || "Something went wrong. Try again.");
  }
};

export const createLGA = async (
  token: string,
  data: {
    name: string;
  },
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${BASE_URL}/lga`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    if (err?.response?.data.status === 401) {
      refreshToken(
        async (arg) => {
          const updatedConfig = {
            ...config,
            headers: { Authorization: `Bearer ${arg.data.access_token}` },
          };
          const response = await axios(updatedConfig);
          execute(response.data);
        },
        (errorNew) => {
          error(errorNew);
        }
      );
    } else {
      err?.response?.data?.message &&
        error(
          err?.response?.data?.message || "Something went wrong. Try again."
        );
    }
  }
};

export const updateLGA = async (
  data: {
    id: string;
    name: string;
  },
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${BASE_URL}/lga`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.access_token}`,
    },
    data,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    if (err?.response?.data.status === 401) {
      refreshToken(
        async (arg) => {
          const updatedConfig = {
            ...config,
            headers: { Authorization: `Bearer ${arg.data.access_token}` },
          };
          const response = await axios(updatedConfig);
          execute(response.data);
        },
        (errorNew) => {
          error(errorNew);
        }
      );
    } else {
      err?.response?.data?.message &&
        error(
          err?.response?.data?.message || "Something went wrong. Try again."
        );
    }
  }
};

export const deleteLGA = async (
  data: {
    id: string;
  },
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${BASE_URL}/lga/${data.id}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.access_token}`,
    },
    data,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    if (err?.response?.data.status === 401) {
      refreshToken(
        async (arg) => {
          const updatedConfig = {
            ...config,
            headers: { Authorization: `Bearer ${arg.data.access_token}` },
          };
          const response = await axios(updatedConfig);
          execute(response.data);
        },
        (errorNew) => {
          error(errorNew);
        }
      );
    } else {
      err?.response?.data?.message &&
        error(
          err?.response?.data?.message || "Something went wrong. Try again."
        );
    }
  }
};
