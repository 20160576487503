import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Nav from "../../components/nav";
import { FormInput } from "../../components/formInput";
import { LoginAuth } from "../../config/formikServices";

const Login = () => {
  const formik = LoginAuth();

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    formik.handleSubmit(event);
  };
  return (
    <div>
      <Nav />

      <div className="bg-primary-100 h-full flex items-center py-12 pb-10 md:py-20">
        <div className=" w-a90 md:w-3/5 xl:w-1/2 2xl:w-2/5 h-full mx-auto bg-white pt-12 p-3 md:px-10  lg:p-10 !py-16 lg:!py-24">
          <h1 className="text-2xl font-bold text-primary-300 py-4">Login</h1>
          <form onSubmit={submit}>
            <FormInput
              name="phoneNumber"
              label="Phone Number"
              placeholder="0000 0000 0000"
              formik={formik}
              type="text"
              color="dark"
            />
            <FormInput
              name="password"
              label="Password"
              placeholder="enter password"
              formik={formik}
              type="password"
              color="dark"
            />
            {/* <Link
              to="/forgot-password"
              className="text-sm text-primary-300 float-right"
            >
              Forgot Password?
            </Link> */}

            <div className="mt-10">
              <Button
                variant="contained"
                fullWidth
                sx={{
                  background: "#532B81",
                  color: "white",
                  padding: "10px 0",
                  marginTop: "20px",
                  ":hover": { bgcolor: "#532B81", color: "white" },
                }}
                type="submit"
              >
                Login
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
