import axios from "axios";
import { BASE_URL } from "./config";
import { store } from "../store";
import {
  SET_ACCESS_TOKEN,
  SET_REFRESH_TOKEN,
  SET_ROLE,
  SET_LOGIN,
  SET_PROFILE,
} from "../store/authSlice";

export const refreshToken = async (
  execute: (arg: any) => void,
  error: (arg: string) => void
) => {
  var config = {
    method: "get",
    url: `${BASE_URL}/auth/refresh-token`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.refresh_token}`,
    },
  };

  try {
    const response = await axios(config);
    if (response.status === 200) {
      execute(response.data);
    } else {
      store.dispatch(SET_ACCESS_TOKEN(null));
      store.dispatch(SET_REFRESH_TOKEN(null));
      store.dispatch(SET_ROLE(null));
      store.dispatch(SET_LOGIN(false));
      store.dispatch(SET_PROFILE(null));
      throw new Error("Failed to refresh token");
    }
  } catch (err: any) {
    store.dispatch(SET_ACCESS_TOKEN(null));
    store.dispatch(SET_REFRESH_TOKEN(null));
    store.dispatch(SET_ROLE(null));
    store.dispatch(SET_LOGIN(false));
    store.dispatch(SET_PROFILE(null));
    if (err.response) {
      error(err.response.data.message);
    } else if (err.request) {
      error("No response received from the server");
    } else {
      error("Error setting up the request");
    }
  }
};

export const signIn = async (
  data: {
    phone_number: string;
    password: string;
  },
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "post",
    url: `${BASE_URL}/auth/login`,
    data: data,
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    err?.response?.data?.message &&
      error(err?.response?.data?.message || "Something went wrong. Try again.");
  }
};

export const signOut = async (
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "delete",
    url: `${BASE_URL}/auth/logout`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.access_token}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    if (err?.response?.data.status === 401) {
      refreshToken(
        async (arg) => {
          const updatedConfig = {
            ...config,
            headers: { Authorization: `Bearer ${arg.data.access_token}` },
          };
          const response = await axios(updatedConfig);
          execute(response.data);
        },
        (errorNew) => {
          error(errorNew);
        }
      );
    } else {
      err?.response?.data?.message &&
        error(
          err?.response?.data?.message || "Something went wrong. Try again."
        );
    }
  }
};
