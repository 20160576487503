import axios from "axios";
import { store } from "../store";
import { refreshToken } from "./auth";
import { BASE_URL } from "./config";

export const getShops = async (
  data: {
    name?: string;
    phone_number?: string;
    page: number;
    area_unit?: string | null;
    service?: string | null;
    is_active?: boolean;
    owner?: string;
  },
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${BASE_URL}/shop?page=${data.page}${
      data?.area_unit ? `&areaunit=${data.area_unit}` : ""
    }${data?.owner ? `&owner=${data.owner}` : ""}${
      data?.service ? `&service=${data.service}` : ""
    }${data?.is_active ? `&is_active=${data.is_active}` : ""}${
      data?.name ? `&name=${data.name}` : ""
    }${data?.phone_number ? `&phone_number=${data.phone_number}` : ""}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.access_token}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    if (err?.response?.data.status === 401) {
      refreshToken(
        async (arg) => {
          const updatedConfig = {
            ...config,
            headers: { Authorization: `Bearer ${arg.data.access_token}` },
          };
          const response = await axios(updatedConfig);
          execute(response.data);
        },
        (errorNew) => {
          error(errorNew);
        }
      );
    } else {
      err?.response?.data?.message &&
        error(
          err?.response?.data?.message || "Something went wrong. Try again."
        );
    }
  }
};
