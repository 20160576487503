import React from "react";
import DashboardLayout from "../layout/dashboardLayout";
import AModal from "@mui/material/Modal";
import { FiTrash2 } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import { getActivityByAreaUnit } from "../../../api/activity";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import moment from "moment";
import { Pagination, PaginationItem } from "@mui/material";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { DelayFor } from "../../../utils/helpers";
import { useNavigate } from "react-router-dom";

const HistoryCard = ({ detail }: { detail: any }) => {
  return (
    <div className="py-3 px-3 cursor-pointer flex flex-row items-center justify-between bg-white rounded-lg mb-4">
      <div className="flex items-center w-[95%]">
        <div>
          <h2 className="text-left text-lg font-bold mb-1">
            {detail?.narration || ""}
          </h2>
          <p className="text-left text-sm mb-1">
            {moment(detail?.created_at).format("DDD, MMM DD, YYYY")}
          </p>
        </div>
      </div>
      {/* <FiTrash2
        size={25}
        className="text-[#696969] hover:text-[#FF0000] cursor-pointer"
        onClick={() => {}}
      /> */}
    </div>
  );
};

const History = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [page, setPage] = React.useState<number>(1)
  const { profile } = useSelector((state: RootState) => state.auth);
  const [history, setHistory] = React.useState<any>(null);
  const [detail, setDetail] = React.useState<any>(null);
  const [dashboard, setDashboard] = React.useState<any>(null);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getActivityByAreaUnit(
      { id: profile?.area_unit?._id, page },
      (response) => {
        dispatch(SET_LOADER(false));
        setHistory(response.data)
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  }, [page]);

  return (
    <DashboardLayout className="py-6 px-6">
      <div className="w-full flex flex-row gap-10 items-center flex-wrap">
      
      <div
        onClick={() => navigate("/total-payments")}
        className="w-full sm:w-[48%] lg:w-[23%] py-4 px-7 rounded-lg bg-primary-300 mb-4 cursor-pointer"
        // style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
      >
        <h3 className="text-grey font-DMSans text-[15px] text-white">
          Total Payments
        </h3>
        <h3 className="text-primary font-DMSans text-white font-extrabold text-[30px] mb-3">
          {dashboard?.num_of_chairman?.toLocaleString() || 0}
        </h3>
      </div>
      
      <div
        onClick={() => navigate("/pending-payments")}
        className="w-full sm:w-[48%] lg:w-[23%] py-4 px-7 rounded-lg bg-primary-300 mb-4 cursor-pointer"
        // style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
      >
        <h3 className="text-grey font-DMSans text-[15px] text-white">
          Pending Payments
        </h3>
        <h3 className="text-primary font-DMSans font-extrabold text-[30px] mb-3 text-white">
          {dashboard?.num_of_artisan?.toLocaleString() || 0}
        </h3>
      </div>
      
      
    </div>
    <div>
      <h3 className="py-4 text-3xl font-semibold text-gray-900 text-center">Pending Payments</h3>
    </div>
      {history?.docs.map((item: any) => (
        <HistoryCard key={item._id} detail={item} />
      ))}
      <div className="w-full px-6 flex justify-end items-end mt-3 ">
        <Pagination
          count={history?.totalPages || 0}
          page={page}
          // color="secondary"
          onChange={(event, value: number) => setPage(value)}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: HiArrowLongLeft,
                next: HiArrowLongRight,
              }}
              {...item}
            />
          )}
        />
      </div>
    </DashboardLayout>
  );
};

export default History;
