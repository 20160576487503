import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { DelayFor } from "../utils/helpers";
import { RootState, store } from "../store";
import { SET_LOADER, SET_MODAL, SET_PAYMENT_MODAL } from "../store/appSlice";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { SET_ADD_USER } from "../store/addUserSlice";
import { signIn } from "../api/auth";
import {
  SET_ACCESS_TOKEN,
  SET_LOGIN,
  SET_PROFILE,
  SET_REFRESH_TOKEN,
  SET_ROLE,
} from "../store/authSlice";
import { createLGA, updateLGA } from "../api/lga";
import { createAreaUnit, updateAreaUnit } from "../api/area_unit";
import { createService, updateService } from "../api/services";
import {
  changeUserPassword,
  createArtisan,
  createShop,
  userProfileUpdate,
} from "../api/user";
import { deposit, depositInvoice, subscribeArtisan } from "../api/wallet";
import { SET_ARTISAN, SET_RESPONSE } from "../store/artisanSlice";
import { SET_SHOPOWNER } from "../store/shopOwnerSlice";
import { SetStateAction } from "react";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const LoginAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return useFormik({
    initialValues: {
      phoneNumber: "",
      password: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .length(11, "Phone number should be 11 digits")
        .matches(phoneRegExp, "Phone number is not valid.")
        .required("Please enter phone number"),
      password: Yup.string()
        .min(5, "Password cannot be less than 5 characters")
        .required("Password is required"),
    }),
    onSubmit: (values) => {
      dispatch(SET_LOADER(true));
      signIn(
        { phone_number: values.phoneNumber, password: values.password },
        (response: any) => {
          console.log(response.data);
          dispatch(SET_ACCESS_TOKEN(response.data.access_token));
          dispatch(SET_REFRESH_TOKEN(response.data.refresh_token));
          dispatch(SET_ROLE(response.data.role));
          dispatch(SET_LOGIN(true));
          if (response.data.role === "admin") navigate("/admin/dashboard");
          else if (response.data.role === "chairman")
            navigate("/chairman/dashboard");
          else if (response.data.role === "artisan")
            navigate("/shop-owner/dashboard");

          dispatch(SET_LOADER(false));
        },
        (error) => {
          dispatch(
            SET_MODAL({
              isError: true,
              message: error,
              show: true,
              title: "Error!",
            })
          );
          DelayFor(() => {
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: error,
                show: false,
                title: "",
              })
            );
          }, 3000);
          dispatch(SET_LOADER(false));
        }
      );
    },
  });
};

export const ForgotPasswordAuth = () => {
  const dispatch = useDispatch();
  return useFormik({
    initialValues: {
      phoneNumber: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid.")
        .length(11, "Phone number should be 11 digits")
        .required("Please enter phone number"),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(
        SET_MODAL({
          isError: false,
          message:
            "We’ve sent you your password via sms to " + values.phoneNumber,
          show: true,
          title: "Done!",
        })
      );
      DelayFor(() => {
        store.dispatch(
          SET_MODAL({
            isError: false,
            message: "",
            show: false,
            title: "",
          })
        );
      }, 2000);
    },
  });
};

export const ApplicantInfoAuth = (handleNext: (values: any) => void) => {
  const dispatch = useDispatch();
  return useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      gender: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Enter applicant first name."),
      lastName: Yup.string().required("Enter applicant last name."),
      gender: Yup.string().required("Select your Gender."),
      phoneNumber: Yup.string()
        .length(11, "Phone number should be 11 digits")
        .matches(phoneRegExp, "Phone number is not valid.")
        .required("Please enter phone number"),
    }),
    onSubmit: (values) => {
      handleNext(values);
    },
  });
};

export const ShopOwnerApplicantInfoAuth = (
  handleNext: (values: any) => void
) => {
  // const dispatch = useDispatch();
  return useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      gender: "",
      shopName: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Enter applicant first name."),
      lastName: Yup.string().required("Enter applicant last name."),
      gender: Yup.string().required("Select your Gender."),
      shopName: Yup.string().required("Enter Shop Name."),
      phoneNumber: Yup.string()
        .length(11, "Phone number should be 11 digits")
        .matches(phoneRegExp, "Phone number is not valid.")
        .required("Please enter phone number"),
    }),
    onSubmit: (values) => {
      // console.log(values, "hey")

      handleNext(values);
    },
  });
};

export const ServiceInfoAuth = (handleNext: () => void) => {
  return useFormik({
    initialValues: {
      lga: "",
      address: "",
      cac: "",
      area_unit: "",
      service: "",
    },
    validationSchema: Yup.object().shape({
      lga: Yup.string().required("LGA is required"),
      address: Yup.string().required("Shop address is required"),
      cac: Yup.string().optional(),
      area_unit: Yup.string().required("Area unit is required."),
      service: Yup.string().required("Type of service is required."),
    }),
    onSubmit: (values) => {
      store.dispatch(
        SET_ARTISAN({
          ...store.getState().artisan.artisan,
          address: values.address,
          cac: values.cac,
          service: values.service,
        })
      );
      handleNext();
    },
  });
};
export const ShopOwnerServiceInfoAuth = (handleNext: () => void) => {
  return useFormik({
    initialValues: {
      lga: "",
      address: "",
      cac: "",
      area_unit: "",
      service: "",
    },
    validationSchema: Yup.object().shape({
      // lga: Yup.string().required("LGA is required"),
      address: Yup.string().required("Shop address is required"),
      cac: Yup.string().optional(),
      area_unit: Yup.string().required("Area unit is required."),
      service: Yup.string().optional(),
    }),
    onSubmit: (values) => {
      store.dispatch(
        SET_SHOPOWNER({
          ...store.getState().shop_owner.shop_owner,
          address: values.address,
          cac: values.cac,
          service: values.service,
        })
      );
      handleNext();
    },
  });
};

export const PhotoInfoAuth = (handleNext: () => void) => {
  return useFormik({
    initialValues: {
      image: null,
    },
    validationSchema: Yup.object().shape({
      image: Yup.mixed().required("Please Select a file"),
    }),
    onSubmit: (values) => {
      store.dispatch(SET_LOADER(true));
      store.dispatch(
        SET_ARTISAN({
          ...store.getState().artisan.artisan,
          image: values.image,
        })
      );
      store.dispatch(SET_LOADER(false));
      handleNext();
    },
  });
};
export const ShopOwnerPhotoInfoAuth = (handleNext: () => void) => {
  return useFormik({
    initialValues: {
      image: null,
    },
    validationSchema: Yup.object().shape({
      image: Yup.mixed().required("Please Select a file"),
    }),
    onSubmit: (values) => {
      store.dispatch(SET_LOADER(true));

      const { profile } = store.getState().auth;
      const { shop_owner } = store.getState().shop_owner;
      createArtisan(
        {
          firstName: shop_owner.firstName,
          lastName: shop_owner.lastName,
          gender: shop_owner.gender,
          phoneNumber: shop_owner.phoneNumber,
          area_unit: profile?.area_unit?._id,
          address: shop_owner.address,
          cac: shop_owner.cac,
          image: values.image,
          service: shop_owner.service,
        },
        (response) => {
          store.dispatch(SET_LOADER(false));
          store.dispatch(SET_RESPONSE(response.data));
          store.dispatch(
            SET_MODAL({
              isError: false,
              message: response.message,
              show: true,
              title: "Success",
            })
          );
          DelayFor(() => {
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: "",
                show: false,
                title: "",
              })
            );
          }, 3000);
          store.dispatch(SET_LOADER(true));

          createShop(
            {
              owner: response.data._id,
              name: shop_owner.shopName,
              areaunit: response.data.area_unit._id,
            },
            (res) => {
              store.dispatch(SET_LOADER(false));
              handleNext();
              store.dispatch(
                SET_MODAL({
                  isError: false,
                  message: res.message,
                  show: true,
                  title: "Success!",
                })
              );
              DelayFor(() => {
                store.dispatch(
                  SET_MODAL({
                    isError: false,
                    message: "",
                    show: false,
                    title: "",
                  })
                );
              }, 3000);
            },
            (err) => {
              store.dispatch(SET_LOADER(false));
              store.dispatch(
                SET_MODAL({
                  isError: true,
                  message: err,
                  show: true,
                  title: "Error!",
                })
              );
              DelayFor(() => {
                store.dispatch(
                  SET_MODAL({
                    isError: false,
                    message: err,
                    show: false,
                    title: "",
                  })
                );
              }, 3000);
            }
          );
        },
        (error) => {
          store.dispatch(SET_LOADER(false));
          store.dispatch(
            SET_MODAL({
              isError: true,
              message: error,
              show: true,
              title: "Error!",
            })
          );
          DelayFor(() => {
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: error,
                show: false,
                title: "",
              })
            );
          }, 3000);
        }
      );

      store.dispatch(SET_LOADER(false));
      handleNext();
    },
  });
};

export const ProfileInfo = () => {
  return useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      gender: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Enter your first name."),
      lastName: Yup.string().required("Enter your last name."),
      gender: Yup.string().required("Select your Gender."),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid.")
        .required("Please enter phone number"),
    }),
    onSubmit: (values) => {
      console.log(values);
      store.dispatch(SET_LOADER(true));
      userProfileUpdate(
        store.getState().auth.access_token || "",
        {
          first_name: values.firstName,
          last_name: values.lastName,
          gender: values.gender,
          phone_number: values.phoneNumber,
        },
        (response) => {
          store.dispatch(SET_LOADER(false));
          store.dispatch(SET_PROFILE(response.data));
          store.dispatch(
            SET_MODAL({
              isError: false,
              message: response.message,
              show: true,
              title: "Success!",
            })
          );
          DelayFor(() => {
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: "",
                show: false,
                title: "",
              })
            );
          }, 3000);
        },
        (error) => {
          store.dispatch(SET_LOADER(false));
          store.dispatch(
            SET_MODAL({
              isError: true,
              message: error,
              show: true,
              title: "Error!",
            })
          );
          DelayFor(() => {
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: error,
                show: false,
                title: "",
              })
            );
          }, 3000);
        }
      );
    },
  });
};

export const PasswordInfo = () => {
  return useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Password is required")
        .min(5, "Password cannot be less than 5 characters"),
      newPassword: Yup.string()
        .required("Password is required")
        .min(5, "Password cannot be less than 5 characters"),
      confirmPassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("newPassword")], "Passwords must match"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      store.dispatch(SET_LOADER(true));
      changeUserPassword(
        { old_password: values.password, new_password: values.newPassword },
        (response) => {
          resetForm({
            values: { confirmPassword: "", newPassword: "", password: "" },
          });
          store.dispatch(SET_LOADER(false));
          store.dispatch(
            SET_MODAL({
              isError: false,
              message: response.message,
              show: true,
              title: "Success!",
            })
          );
          DelayFor(() => {
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: "",
                show: false,
                title: "",
              })
            );
          }, 3000);
        },
        (error) => {
          store.dispatch(SET_LOADER(false));
          store.dispatch(
            SET_MODAL({
              isError: true,
              message: error,
              show: true,
              title: "Error!",
            })
          );
          DelayFor(() => {
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: error,
                show: false,
                title: "",
              })
            );
          }, 3000);
        }
      );
    },
  });
};

export const FundInfo = () => {
  // window.location.href
  return useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string()
        .matches(/^[0-9]+$/, "Amount should contain just numbers")
        .required("Amount is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      store.dispatch(SET_LOADER(true));
      deposit(
        { amount: Number(values.amount), callback_url: window.location.href },
        (response) => {
          resetForm({ values: { amount: "" } });
          window.location.href = response.data.link;
          store.dispatch(SET_LOADER(false));
        },
        (error) => {
          store.dispatch(SET_LOADER(false));
          store.dispatch(
            SET_MODAL({
              isError: true,
              message: error,
              show: true,
              title: "Error!",
            })
          );
          DelayFor(() => {
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: error,
                show: false,
                title: "",
              })
            );
          }, 3000);
        }
      );
    },
  });
};

export const FundInvoiceInfo = () => {
  // window.location.href
  return useFormik({
    initialValues: {
      amount: "",
      invoice: "",
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string()
        .matches(/^[0-9]+$/, "Amount should contain just numbers")
        .required("Amount is required"),
      invoice: Yup.string().required("Amount is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      store.dispatch(SET_LOADER(true));
      depositInvoice(
        { amount: Number(values.amount), invoice: values.invoice },
        (response) => {
          resetForm({ values: { amount: "", invoice: "" } });
          window.location.href = window.location.href;
          store.dispatch(SET_LOADER(false));
        },
        (error) => {
          store.dispatch(SET_LOADER(false));
          store.dispatch(
            SET_MODAL({
              isError: true,
              message: error,
              show: true,
              title: "Error!",
            })
          );
          DelayFor(() => {
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: error,
                show: false,
                title: "",
              })
            );
          }, 3000);
        }
      );
    },
  });
};

export const PayInfo = () => {
  const { id } = useParams();
  return useFormik({
    initialValues: {
      duration: "",
    },
    validationSchema: Yup.object().shape({
      duration: Yup.string()
        .matches(/^[0-9]+$/, "Duration should contain just numbers")
        .required("Duration is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      store.dispatch(SET_LOADER(true));
      subscribeArtisan(
        { duration: Number(values.duration), user_id: id || "" },
        (response) => {
          resetForm({ values: { duration: "" } });
          store.dispatch(SET_LOADER(false));
          store.dispatch(
            SET_MODAL({
              isError: false,
              message: response.message,
              show: true,
              title: "Success!",
            })
          );
          DelayFor(() => {
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: "",
                show: false,
                title: "",
              })
            );
          }, 3000);
        },
        (error) => {
          store.dispatch(SET_LOADER(false));
          store.dispatch(
            SET_MODAL({
              isError: true,
              message: error,
              show: true,
              title: "Error!",
            })
          );
          DelayFor(() => {
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: error,
                show: false,
                title: "",
              })
            );
          }, 3000);
        }
      );
    },
  });
};

export const PayInfoShopOwner = (
  setOpenModal: React.Dispatch<SetStateAction<boolean>>,
  setDuration: React.Dispatch<SetStateAction<string | null>>
) => {
  // const { id } = useParams();
  return useFormik({
    initialValues: {
      duration: "",
    },
    validationSchema: Yup.object().shape({
      duration: Yup.string()
        .matches(/^[0-9]+$/, "Duration should contain just numbers")
        .required("Duration is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setOpenModal(true);
      setDuration(values.duration);
    },
  });
};

export const PayShopOwner = (handleNext: () => void) => {
  return useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string()
        .matches(/^[0-9]+$/, "Duration should contain just numbers")
        .required("Amount is required"),
    }),
    onSubmit: () => {
      console.log("first");
      store.dispatch(SET_LOADER(true));

      const { profile } = store.getState().auth;
      const { shop_owner } = store.getState().shop_owner;
      createArtisan(
        {
          firstName: shop_owner.firstName,
          lastName: shop_owner.lastName,
          gender: shop_owner.gender,
          phoneNumber: shop_owner.phoneNumber,
          area_unit: profile?.area_unit?._id,
          address: shop_owner.address,
          cac: shop_owner.cac,
          image: shop_owner.image,
          service: shop_owner.service,
        },
        (response) => {
          store.dispatch(SET_LOADER(false));
          store.dispatch(SET_RESPONSE(response.data));
          store.dispatch(
            SET_MODAL({
              isError: false,
              message: response.message,
              show: true,
              title: "Success!",
            })
          );
          DelayFor(() => {
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: "",
                show: false,
                title: "",
              })
            );
          }, 3000);
          store.dispatch(SET_LOADER(true));
          createShop(
            {
              owner: response.data._id,
              name: shop_owner.shopName,
              areaunit: response.data.area_unit._id,
            },
            (res) => {
              store.dispatch(SET_LOADER(false));
              handleNext();
              store.dispatch(
                SET_MODAL({
                  isError: false,
                  message: res.message,
                  show: true,
                  title: "Success!",
                })
              );
              DelayFor(() => {
                store.dispatch(
                  SET_MODAL({
                    isError: false,
                    message: "",
                    show: false,
                    title: "",
                  })
                );
              }, 3000);
            },
            (err) => {
              store.dispatch(SET_LOADER(false));
              store.dispatch(
                SET_MODAL({
                  isError: true,
                  message: err,
                  show: true,
                  title: "Error!",
                })
              );
              DelayFor(() => {
                store.dispatch(
                  SET_MODAL({
                    isError: false,
                    message: err,
                    show: false,
                    title: "",
                  })
                );
              }, 3000);
            }
          );
        },
        (error) => {
          store.dispatch(SET_LOADER(false));
          store.dispatch(
            SET_MODAL({
              isError: true,
              message: error,
              show: true,
              title: "Error!",
            })
          );
          DelayFor(() => {
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: error,
                show: false,
                title: "",
              })
            );
          }, 3000);
        }
      );
      store.dispatch(SET_LOADER(false));

      // handleNext()
    },
  });
};

export const PayArtisan = (handleNext: () => void) => {
  return useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string()
        .matches(/^[0-9]+$/, "Duration should contain just numbers")
        .required("Amount is required"),
    }),
    onSubmit: (values, helpers) => {
      store.dispatch(SET_PAYMENT_MODAL({ show: true, handleNext, duration: Number(values.amount) }));
    },
  });
};

export const LGAInfo = (
  callback: (arg: any) => void,
  type: "edit" | "create" = "create",
  id?: string
) => {
  return useFormik({
    initialValues: {
      lga: "",
    },
    validationSchema: Yup.object().shape({
      lga: Yup.string().required("Enter LGA to be created"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      if (type === "create") {
        store.dispatch(SET_LOADER(true));
        createLGA(
          store.getState().auth.access_token || "",
          { name: values.lga },
          (response) => {
            resetForm({ values: { lga: "" } });
            callback(response.data);
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: response.message,
                show: true,
                title: "Success!",
              })
            );
            DelayFor(() => {
              store.dispatch(
                SET_MODAL({
                  isError: false,
                  message: "",
                  show: false,
                  title: "",
                })
              );
            }, 3000);
            store.dispatch(SET_LOADER(false));
          },
          (error) => {
            store.dispatch(
              SET_MODAL({
                isError: true,
                message: error,
                show: true,
                title: "Error!",
              })
            );
            DelayFor(() => {
              store.dispatch(
                SET_MODAL({
                  isError: false,
                  message: error,
                  show: false,
                  title: "",
                })
              );
            }, 3000);
            store.dispatch(SET_LOADER(false));
          }
        );
      } else if (type === "edit") {
        updateLGA(
          { id: id || "", name: values.lga },
          (response) => {
            callback(response.data);
            resetForm({ values: { lga: "" } });
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: response.message,
                show: true,
                title: "Success!",
              })
            );
            DelayFor(() => {
              store.dispatch(
                SET_MODAL({
                  isError: false,
                  message: "",
                  show: false,
                  title: "",
                })
              );
            }, 3000);
            store.dispatch(SET_LOADER(false));
          },
          (error) => {
            store.dispatch(
              SET_MODAL({
                isError: true,
                message: error,
                show: true,
                title: "Error!",
              })
            );
            DelayFor(() => {
              store.dispatch(
                SET_MODAL({
                  isError: false,
                  message: error,
                  show: false,
                  title: "",
                })
              );
            }, 3000);
            store.dispatch(SET_LOADER(false));
          }
        );
      }
    },
  });
};

export const ServiceInfo = (
  callback: (arg: any) => void,
  type: "edit" | "create" = "create",
  id?: string
) => {
  return useFormik({
    initialValues: {
      service: "",
      amount: "",
    },
    validationSchema: Yup.object().shape({
      service: Yup.string().required("Field cannot be empty."),
      amount: Yup.string().required("Field cannot be empty."),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      if (type === "create") {
        store.dispatch(SET_LOADER(true));
        createService(
          store.getState().auth.access_token || "",
          { name: values.service, fee: Number(values.amount) },
          (response) => {
            callback(response.data);
            resetForm({ values: { service: "", amount: "" } });
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: response.message,
                show: true,
                title: "Success!",
              })
            );
            DelayFor(() => {
              store.dispatch(
                SET_MODAL({
                  isError: false,
                  message: "",
                  show: false,
                  title: "",
                })
              );
            }, 3000);
            store.dispatch(SET_LOADER(false));
          },
          (error) => {
            store.dispatch(
              SET_MODAL({
                isError: true,
                message: error,
                show: true,
                title: "Error!",
              })
            );
            DelayFor(() => {
              store.dispatch(
                SET_MODAL({
                  isError: false,
                  message: error,
                  show: false,
                  title: "",
                })
              );
            }, 3000);
            store.dispatch(SET_LOADER(false));
          }
        );
      } else if (type === "edit") {
        updateService(
          { id: id || "", name: values.service, fee: Number(values.amount) },
          (response) => {
            callback(response.data);
            resetForm({ values: { service: "", amount: "" } });
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: response.message,
                show: true,
                title: "Success!",
              })
            );
            DelayFor(() => {
              store.dispatch(
                SET_MODAL({
                  isError: false,
                  message: "",
                  show: false,
                  title: "",
                })
              );
            }, 3000);
            store.dispatch(SET_LOADER(false));
          },
          (error) => {
            store.dispatch(
              SET_MODAL({
                isError: true,
                message: error,
                show: true,
                title: "Error!",
              })
            );
            DelayFor(() => {
              store.dispatch(
                SET_MODAL({
                  isError: false,
                  message: error,
                  show: false,
                  title: "",
                })
              );
            }, 3000);
            store.dispatch(SET_LOADER(false));
          }
        );
      }
    },
  });
};

export const AreaUnitInfo = (
  callback: (arg: any) => void,
  type: "edit" | "create" = "create",
  id?: string
) => {
  return useFormik({
    initialValues: {
      lga: "",
      areaUnit: "",
    },
    validationSchema: Yup.object().shape({
      lga: Yup.string().required("Select a LGA for the Area Unit"),
      areaUnit: Yup.string().required("Area Unit for LGA cannot be empty."),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      if (type === "create") {
        store.dispatch(SET_LOADER(true));
        createAreaUnit(
          store.getState().auth.access_token || "",
          {
            name: values.areaUnit,
            lga: values.lga,
          },
          (response) => {
            callback(response.data);
            resetForm({ values: { areaUnit: "", lga: "" } });
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: response.message,
                show: true,
                title: "Success!",
              })
            );
            DelayFor(() => {
              store.dispatch(
                SET_MODAL({
                  isError: false,
                  message: "",
                  show: false,
                  title: "",
                })
              );
            }, 3000);
            store.dispatch(SET_LOADER(false));
          },
          (error) => {
            store.dispatch(
              SET_MODAL({
                isError: true,
                message: error,
                show: true,
                title: "Error!",
              })
            );
            DelayFor(() => {
              store.dispatch(
                SET_MODAL({
                  isError: false,
                  message: error,
                  show: false,
                  title: "",
                })
              );
            }, 3000);
            store.dispatch(SET_LOADER(false));
          }
        );
      } else if (type === "edit") {
        updateAreaUnit(
          store.getState().auth.access_token || "",
          {
            id: id || "",
            name: values.areaUnit,
            lga: values.lga,
          },
          (response) => {
            callback(response.data);
            resetForm({ values: { areaUnit: "", lga: "" } });
            store.dispatch(
              SET_MODAL({
                isError: false,
                message: response.message,
                show: true,
                title: "Success!",
              })
            );
            DelayFor(() => {
              store.dispatch(
                SET_MODAL({
                  isError: false,
                  message: "",
                  show: false,
                  title: "",
                })
              );
            }, 3000);
            store.dispatch(SET_LOADER(false));
          },
          (error) => {
            store.dispatch(
              SET_MODAL({
                isError: true,
                message: error,
                show: true,
                title: "Error!",
              })
            );
            DelayFor(() => {
              store.dispatch(
                SET_MODAL({
                  isError: false,
                  message: error,
                  show: false,
                  title: "",
                })
              );
            }, 3000);
            store.dispatch(SET_LOADER(false));
          }
        );
      }
    },
  });
};

export const UserInfo = (handleNext: () => void) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.addUser);
  return useFormik({
    initialValues: {
      ...user,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Enter your first name."),
      lastName: Yup.string().required("Enter your last name."),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid.")
        .required("Please enter phone number"),
      gender: Yup.string().required("Select your gender."),
      role: Yup.string().required("Select a role"),
      lga: Yup.string().when("role", {
        is: (role: string) => role === "chairman",
        then: (scheme) => scheme.required("LGA cannot be empty."),
        otherwise: (scheme) => scheme.notRequired(),
      }),
      areaUnit: Yup.string().when("lga", {
        is: (lga: string) => lga && lga !== "",
        then: (scheme) => scheme.required("select an area unit"),
        otherwise: (scheme) => scheme,
      }),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(SET_ADD_USER(values));
      handleNext();
    },
  });
};
