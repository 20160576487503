import React, { useContext } from "react";
import Box from "@mui/material/Box";
import AModal from "@mui/material/Modal";
import { SuccessIcon } from "../assets/icons/icons";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { SET_MODAL } from "../store/appSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  boxShadow: 0,
  m: "0 auto",
  p: 4,
};

const Modal = () => {
  const isModalVisible = useSelector(
    (state: RootState) => state.app.isModalVisible
  );
  const dispatch = useDispatch();

  const handleClose = () =>
    dispatch(
      SET_MODAL({ ...isModalVisible, show: false, message: "", title: "" })
    );

  return (
    <AModal
      open={isModalVisible.show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="AModal">
        {isModalVisible.isError ? (
          <img
            src="https://img.icons8.com/material/80/FF0000/cancel--v1.png"
            className="mx-auto mb-5"
          />
        ) : (
          <SuccessIcon />
        )}
        <h1 className="font-bold text-xl xl:text-3xl text-primary-300 text-center">
          {isModalVisible.title}
        </h1>
        <p className="text-grey-300 text-base text-center mb-5">
          {isModalVisible.message}
        </p>
      </Box>
    </AModal>
  );
};

export default Modal;
