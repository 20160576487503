import React from "react";

const LandingSearch = ({
  name,
  onChange,
  data,
}: {
  name: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  data: any[];
}) => {
  return (
    <div className="relative w-full  mx-auto">
      {/* <span className={`${color === 'light' && 'text-white'} text-sm`}>{ label }</span> */}
      <div
        className={`flex justify-between bg-transparent items-center rounded-full border border-solid border-primary-300 px-3 text-sm`}
      >
        <select
          name={name}
          className="w-full bg-transparent py-3 focus:outline-none"
          onChange={onChange}
        >
          <option value="" selected disabled>
            Select LGA
          </option>
          {data?.map((data, idx) => (
            <option value={data?.value} key={idx}>
              {data.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default LandingSearch;
