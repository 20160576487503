import React from "react";
import MenuBar from "./adminMenuBar";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/icons/riv-logo.svg";
import avatar from "../../../assets/icons/avatar.svg";
import { Menu, MenuItem, useMediaQuery } from "@mui/material";
import { MdMenu } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { signOut } from "../../../api/auth";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import {
  SET_ACCESS_TOKEN,
  SET_REFRESH_TOKEN,
  SET_ROLE,
  SET_LOGIN,
  SET_PROFILE,
} from "../../../store/authSlice";
import { DelayFor, capitalize } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const AdminDashboardLayout = ({
  children,
  className,
}: {
  children: JSX.Element | JSX.Element[];
  className?: string;
}) => {
  const [show, setShow] = React.useState<boolean>(true);
  const matches = useMediaQuery("(max-width:1024px)");
  React.useEffect(() => {
    if (!matches) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [matches]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { profile } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  return (
    <div className="w-screen h-screen flex relative">
      <MenuBar show={show} />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => navigate("/admin/settings")}>
          My Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(SET_LOADER(true));
            signOut(
              (response) => {
                dispatch(SET_LOADER(false));
                dispatch(SET_ACCESS_TOKEN(null));
                dispatch(SET_REFRESH_TOKEN(null));
                dispatch(SET_ROLE(null));
                dispatch(SET_LOGIN(false));
                dispatch(SET_PROFILE(null));
                dispatch(
                  SET_MODAL({
                    isError: false,
                    message: response.message,
                    show: true,
                    title: "Success!",
                  })
                );
                DelayFor(() => {
                  dispatch(
                    SET_MODAL({
                      isError: false,
                      message: "",
                      show: false,
                      title: "",
                    })
                  );
                }, 3000);
              },
              (error) => {
                dispatch(SET_LOADER(false));
                dispatch(
                  SET_MODAL({
                    isError: true,
                    message: error,
                    show: true,
                    title: "Error!",
                  })
                );
                DelayFor(() => {
                  dispatch(
                    SET_MODAL({
                      isError: false,
                      message: error,
                      show: false,
                      title: "",
                    })
                  );
                }, 3000);
              }
            );
          }}
        >
          Logout
        </MenuItem>
      </Menu>

      {/* APP BAR AND DASHBOARD */}
      <div className="flex-grow bg-white w-[100vw] lg:w-[80vw]">
        <div className="w-full h-[12vh] lg:h-[10vh] bg-white flex items-center lg:justify-end justify-between px-6 shadow-sm z-20">
          <div className="lg:hidden flex items-center">
            <Link to="/">
              <Logo height={50} />
            </Link>
          </div>

          <div className="flex w-auto items-center">
            {show ? (
              <IoMdClose
                size={23}
                className="block lg:hidden"
                onClick={() => setShow(false)}
              />
            ) : (
              <MdMenu
                size={23}
                className="block lg:hidden"
                onClick={() => setShow(true)}
              />
            )}
            <p className="mr-2 font-bold hidden lg:flex">
            {profile?.first_name} {profile?.last_name} ({capitalize(profile?.type || "")})
            </p>
            <button
              className="w-[40px] h-[40px] hidden lg:flex items-center"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <img src={avatar} alt="profile" className="w-full h-full" />
            </button>
          </div>
        </div>
        <div
          className={`w-full max-h-[88vh] h-full lg:max-h-[90vh] overflow-y-auto bg-primary-100 ${className}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardLayout;
