import React from "react";
import DashboardLayout from "../layout/shopOwnerDashboardLayout";
import { FormInput } from "../../../components/formInput";
import { PasswordInfo, ProfileInfo } from "../../../config/formikServices";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import SelectInput from "../../../components/selectInput";
import ShopOwnerDashboardLayout from "../layout/shopOwnerDashboardLayout";

const ShopOwnerSettings = () => {
  const { profile } = useSelector((state: RootState) => state.auth);
  const profileFormik = ProfileInfo();
  const passwordFormik = PasswordInfo();

  React.useEffect(() => {
    profileFormik.setValues({
      firstName: profile?.first_name || "",
      lastName: profile?.last_name || "",
      gender: profile?.gender || "",
      phoneNumber: profile?.phone_number || "",
    });
  }, [profile]);
  return (
    <ShopOwnerDashboardLayout>
      <div className="py-6 px-6">
        <div className="bg-white p-5 lg:p-10">
          <div className="">
            <h1 className="text-primary-300 font-black text-xl lg:text-2xl">
              Edit Profile
            </h1>
            <form
              className=""
              onSubmit={(e) => {
                e.preventDefault();
                profileFormik.handleSubmit(e);
              }}
            >
              <div className="grid md:grid-cols-2 md:gap-3">
                <FormInput
                  name="firstName"
                  label="First Name"
                  placeholder="enter your first name here"
                  formik={profileFormik}
                  type="text"
                  color="dark"
                />
                <FormInput
                  name="lastName"
                  label="Last Name"
                  placeholder="enter your last name here"
                  formik={profileFormik}
                  type="text"
                  color="dark"
                />
                <FormInput
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="000 0000 000"
                  formik={profileFormik}
                  type="text"
                  color="dark"
                />
                <div className="w-full h-full flex items-center pt-5">
                  <SelectInput
                    label="Gender"
                    name="gender"
                    formik={profileFormik}
                    data={[
                      { id: "male", name: "male" },
                      { id: "female", name: "female" },
                    ]}
                    placeholder="Select your Gender"
                  />
                </div>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    background: "#532B81",
                    color: "white",
                    padding: "10px 0",
                    marginTop: "20px",
                    ":hover": { bgcolor: "#532B81", color: "white" },
                  }}
                  type="submit"
                >
                  Save Changes
                </Button>
              </div>
            </form>
          </div>

          <div className="my-5 mt-14">
            <h1 className="text-primary-300 font-black text-xl lg:text-2xl">
              Set Password
            </h1>
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                passwordFormik.handleSubmit(e);
              }}
            >
              <div className=" lg:w-1/2">
                <FormInput
                  name="password"
                  label="Current Password"
                  placeholder="******"
                  formik={passwordFormik}
                  type="password"
                  color="dark"
                />
                <FormInput
                  name="newPassword"
                  label="New Password"
                  placeholder="******"
                  formik={passwordFormik}
                  type="password"
                  color="dark"
                />
                <FormInput
                  name="confirmPassword"
                  label="Confirm Password"
                  placeholder="******"
                  formik={passwordFormik}
                  type="password"
                  color="dark"
                />

                {/* <FormInput name="currentPassword" label="Current Password" placeholder="*******" formik={formik} type="password" color="dark" /> */}
                {/* <FormInput name="password" label="New Password" placeholder="*******" formik={formik} type="password" color="dark" />
                                <FormInput name="confirmPassword" label="Confirm Password" placeholder="*******" formik={formik} type="password" color="dark" /> */}

                {/* <Button className="setting-btn" type='submit'>Save changes</Button> */}
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    background: "#532B81",
                    color: "white",
                    padding: "10px 0",
                    marginTop: "20px",
                    ":hover": { bgcolor: "#532B81", color: "white" },
                  }}
                  type="submit"
                >
                  Save Changes
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ShopOwnerDashboardLayout>
  );
};

export default ShopOwnerSettings;
