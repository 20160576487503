import React from "react";
import AModal from "@mui/material/Modal";
import { AiOutlineClose } from "react-icons/ai";
import Webcam from "react-webcam";
import logo from "../../../assets/imgs/nasarawa-logo.png";
import { Stepper, Step, StepLabel, Button, capitalize } from "@mui/material";
import { FormInput } from "../../../components/formInput";
import {
  ApplicantInfoAuth,
  PayArtisan,
  PayShopOwner,
  PhotoInfoAuth,
  ServiceInfoAuth,
} from "../../../config/formikServices";
import SelectInput from "../../../components/selectInput";
import { useReactToPrint } from "react-to-print";
import { MdCloudUpload } from "react-icons/md";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useDispatch } from "react-redux";
import { getAllServices } from "../../../api/services";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import { DelayFor } from "../../../utils/helpers";
import { SET_ARTISAN } from "../../../store/artisanSlice";
import { getAllLGA } from "../../../api/lga";
import moment from "moment";

const steps = ["Applicant Info", "Service", "Photo Upload", "Payment", "ID"];

const ApplicantInfo = ({
  handleNext,
  handlePrev,
}: {
  handleNext: () => void;
  handlePrev: () => void;
}) => {
  const dispatch = useDispatch();
  const { artisan } = useSelector((state: RootState) => state.artisan);
  const formik = ApplicantInfoAuth((values: any) => {
    const data = { ...artisan, ...values };
    dispatch(SET_ARTISAN(data));
    handleNext();
  });
  // console.log(artisan);

  React.useEffect(() => {
    formik.setValues({
      firstName: artisan.firstName,
      lastName: artisan.lastName,
      gender: artisan.gender,
      phoneNumber: artisan.phoneNumber,
    });
    // eslint-disable-next-line
  }, []);

  // console.log(artisan);

  return (
    <div className="px-3 w-full mt-5 max-h-[300px] overflow-auto">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <FormInput
          name="firstName"
          label="First Name"
          placeholder="enter your first name name here"
          formik={formik}
          type="text"
          color="dark"
        />
        <FormInput
          name="lastName"
          label="Last Name"
          placeholder="enter your last name name here"
          formik={formik}
          type="text"
          color="dark"
        />
        <FormInput
          name="phoneNumber"
          label="Phone Number"
          placeholder="0000 0000 0000"
          formik={formik}
          type="text"
          color="dark"
        />
        <SelectInput
          label="Gender"
          name="gender"
          formik={formik}
          data={[
            { id: "male", name: "male" },
            { id: "female", name: "female" },
          ]}
          placeholder="Select a Gender"
        />
        <Button variant="contained" type="submit" fullWidth>
          Next
        </Button>
      </form>
    </div>
  );
};

const Payment = ({
  handleNext,
  handlePrev,
}: {
  handleNext: () => void;
  handlePrev: () => void;
}) => {
  const formik = PayArtisan(handleNext);

  return (
    <div className="h-[90%] w-[90%] sm:h-auto md:w-auto  lg:h-auto bg-white rounded-lg py-3 px-3">
      <div className="w-full flex justify-end items-center"></div>
      <div className="flex h-full px-5 flex-col justify-center items-center">
        <h2 className="font-Grotesk text-[25px] text-primary font-bold mb-6">
          Amount
        </h2>
        <form
          className="min-w-[250px] !w-full !max-w-[450px]"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <FormInput
            name="amount"
            label="Enter Duration (Months)"
            placeholder="Enter Duration in Months"
            formik={formik}
            type="text"
            color="dark"
          />
          {/* <Button fullWidth variant="contained" type="submit">
            Pay Now
          </Button> */}
          <div className="w-full justify-between flex items-center my-5">
            <Button
              variant="outlined"
              onClick={handlePrev}
              sx={{ width: "47%" }}
            >
              Previous
            </Button>
            <Button variant="contained" type="submit" sx={{ width: "47%" }}>
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Service = ({
  handleNext,
  handlePrev,
}: {
  handleNext: () => void;
  handlePrev: () => void;
}) => {
  const dispatch = useDispatch();
  const [services, setServices] = React.useState<any[]>([]);
  const { artisan } = useSelector((state: RootState) => state.artisan);
  const { profile } = useSelector((state: RootState) => state.auth);
  const [lga, setLga] = React.useState<any[]>([]);

  const formik = ServiceInfoAuth(handleNext);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getAllServices(
      (response) => {
        dispatch(SET_LOADER(false));
        setServices(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  }, []);

  React.useEffect(() => {
    formik.setValues({
      address: artisan.address,
      area_unit: profile?.area_unit?.name,
      cac: artisan.cac,
      lga: profile?.area_unit?.lga?.name,
      service: artisan.service,
    });
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    getAllLGA(
      (response) => {
        setLga(response.data);
      },
      (error) => {
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  }, []);
  return (
    <div className="px-3 w-full mt-5 max-h-[300px] overflow-auto">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <FormInput
          name="lga"
          label="LGA"
          placeholder="Enter your local government Area"
          formik={formik}
          color="dark"
          type="text"
        />
        <FormInput
          name="address"
          label="Shop Address"
          placeholder="Enter applicant shop address"
          formik={formik}
          type="text"
          color="dark"
        />
        <FormInput
          name="cac"
          label="CAC (Optional)"
          placeholder="Enter applicant CAC number"
          formik={formik}
          type="text"
          color="dark"
        />
        <FormInput
          name="area_unit"
          label="Area Unit"
          placeholder="Enter applicant Area Unit"
          formik={formik}
          disable
          type="text"
          color="dark"
        />
        <SelectInput
          label="Type of Service"
          name="service"
          formik={formik}
          data={services.map((item: any) => ({
            id: item._id,
            name: item.name,
          }))}
          placeholder="Type of Service"
        />
        <div className="w-full justify-between flex items-center mb-5">
          <Button variant="outlined" onClick={handlePrev} sx={{ width: "47%" }}>
            Previous
          </Button>
          <Button variant="contained" type="submit" sx={{ width: "47%" }}>
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

const PhotoUpload = ({
  handleNext,
  handlePrev,
}: {
  handleNext: () => void;
  handlePrev: () => void;
}) => {
  const [image, setImage] = React.useState<string>("");
  const imageRef = React.useRef<any>(null);
  const formik = PhotoInfoAuth(handleNext);

  const handleCapture = React.useCallback(() => {
    const imageSrc = imageRef?.current?.getScreenshot();
    console.log(imageSrc);

    fetch(imageSrc)
      .then((res) => res.blob())
      .then((res) => {
        setImage(imageSrc);
        formik.setFieldValue("image", res);
      })
      .catch((err) => console.log(err));
  }, [imageRef]);
  return (
    <div className="px-3 w-full mt-5 max-h-[300px] overflow-auto">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <p className="font-light">Upload a Photo</p>

        <div className="w-full grid place-items-center border border-dashed py-16 rounded-xl mt-2">
          {image ? (
            <img
              src={image}
              alt="passport"
              className="w-32 h-32 object-cover"
            />
          ) : (
            <div className="flex flex-col text-center">
              <Webcam width={200} height={200} ref={imageRef} />

              <Button
                id="passport"
                variant="contained"
                sx={{
                  background: "#532B81",
                  color: "white",
                  ":hover": { bgcolor: "#532B81", color: "white" },
                }}
                fullWidth
                onClick={handleCapture}
                startIcon={<i className="fa-solid fa-up-from-line"></i>}
              >
                <MdCloudUpload color="white" />
                Capture
              </Button>
              <span className="font-light mt-4">Upload a photo of you</span>
            </div>
          )}
        </div>
        {formik?.errors["image"] ? (
          <p className={`text-error text-xs px-2 py-0 bg-transparent`}>
            {formik?.errors["image"]}
          </p>
        ) : null}

        <div className="w-full justify-between flex items-center my-5">
          <Button variant="outlined" onClick={handlePrev} sx={{ width: "47%" }}>
            Previous
          </Button>
          <Button variant="contained" type="submit" sx={{ width: "47%" }}>
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

const PrintID = () => {
  const { response } = useSelector((state: RootState) => state.artisan);
  const componentRef = React.useRef<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Artisan ID Card",
  });
  console.log(response);
  return (
    <div className="px-3 w-full mt-5 max-h-[300px] overflow-auto">
      <div
        ref={componentRef}
        className="max-w-[300px] shadow-lg my-2 py-4 px-3 bg-white mx-auto rounded-lg"
      >
        <div className="w-full h-[170px] mb-3">
          <img
            src={logo}
            alt=""
            className="w-[30px] h-[30px] object-contain absolute right-3 top-3"
          />
          <img
            src={response?.image?.url}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <h2 className="text-center text-2xl font-bold mb-2">
          {response?.first_name} {response?.last_name}
        </h2>
        <p className="text-center text-xl font-bold text-primary-300 mb-2">
          {response?.service?.name}
        </p>
        <p className="text-center text-md">
          Area Unit: {response?.area_unit?.name}
        </p>
        <p className="text-center text-md">
          Shop Address: {response?.shop?.owner?.address}
        </p>
        <p className="text-center text-md">
          LGA: {response?.area_unit?.lga?.name}
        </p>
        <p className="text-center text-md">
          Issuing Date: {moment(new Date()).format("DD/MM/YYYY")}
        </p>
        <p className="text-center text-md">
          Expiry Date: {moment(new Date()).add("1", "year").format("DD/MM/YYYY")}
        </p>
      </div>
      <Button
        variant="contained"
        onClick={handlePrint}
        fullWidth
        sx={{ mt: 2 }}
      >
        Print Card
      </Button>
    </div>
  );
};

const AddArtisan = ({ show, hide }: { show: boolean; hide: () => void }) => {
  const [activeStep, setActiveStep] = React.useState<number>(0);
  return (
    <AModal
      open={show}
      onClose={() => {
        setActiveStep(0);
        hide();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <div className="w-[90%] sm:w-[80%] md:w-[60%] lg:w-[50%] max-h-full bg-white px-3 py-4 rounded-lg">
        <div className="w-full flex justify-end items-center mb-3">
          <AiOutlineClose
            size={23}
            className="text-primary-300 cursor-pointer"
            onClick={() => {
              setActiveStep(0);
              hide();
            }}
          />
        </div>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, idx) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 ? (
          <ApplicantInfo
            handleNext={() =>
              setActiveStep(
                activeStep === steps.length - 1 ? activeStep : activeStep + 1
              )
            }
            handlePrev={() =>
              setActiveStep(activeStep === 0 ? activeStep : activeStep - 1)
            }
          />
        ) : activeStep === 1 ? (
          <Service
            handleNext={() =>
              setActiveStep(
                activeStep === steps.length - 1 ? activeStep : activeStep + 1
              )
            }
            handlePrev={() => setActiveStep(activeStep - 1)}
          />
        ) : activeStep === 2 ? (
          <PhotoUpload
            handleNext={() =>
              setActiveStep(
                activeStep === steps.length - 1 ? activeStep : activeStep + 1
              )
            }
            handlePrev={() => setActiveStep(activeStep - 1)}
          />
        ) : activeStep === 3 ? (
          <Payment
            handleNext={() =>
              setActiveStep(
                activeStep === steps.length - 1 ? activeStep : activeStep + 1
              )
            }
            handlePrev={() => setActiveStep(activeStep - 1)}
          />
        ) : activeStep === 4 ? (
          <PrintID />
        ) : null}
      </div>
    </AModal>
  );
};

export default AddArtisan;
