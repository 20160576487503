import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { boolean } from "yup";

export interface Root {
  loader: boolean;
  isModalVisible: {
    show: boolean;
    title: string;
    message: string;
    isError: boolean;
  };
  confirmModal: {
    duration: number;
    show: boolean,
    handleNext: (() => void) 
  }
}

const initialState: Root = {
  loader: false,
  isModalVisible: {
    show: false,
    isError: false,
    message: "",
    title: "",
  },
  confirmModal: {
    duration: 0,
    show: false,
    handleNext: () =>{}
  }
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    SET_MODAL: (
      state,
      action: PayloadAction<{
        message: string;
        show: boolean;
        isError: boolean;
        title: string;
      }>
    ) => {
      state.isModalVisible = action.payload;
    },
    SET_LOADER: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    SET_PAYMENT_MODAL: (state, action: PayloadAction<{
      duration: number;
      show: boolean;
      handleNext: (()=> void) 
    }> ) => {
      state.confirmModal = action.payload
    }
  },
});

export const { SET_LOADER, SET_MODAL, SET_PAYMENT_MODAL } = appSlice.actions;

export default appSlice.reducer;
