import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface Root {
  shop_owner: ShopOwner;
  response: any;
}

interface ShopOwner {
  firstName: string;
  lastName: string;
  gender: string;
  address: string;
  cac: string;
  service: string;
  phoneNumber: string;
  shopName: string,
  image: File | null;
}

const initialState: Root = {
  shop_owner: {
    firstName: "",
    lastName: "",
    gender: "",
    phoneNumber: "",
    address: "",
    cac: "",
    service: "",
    shopName: "",
    image: null
  },
  response: null,
};

export const shopOwnerSlice = createSlice({
  name: "shop_owner",
  initialState,
  reducers: {
    SET_SHOPOWNER: (state, action: PayloadAction<ShopOwner | null>) => {
      state.shop_owner = action.payload
        ? action.payload
        : {
            firstName: "",
            lastName: "",
            gender: "",
            phoneNumber: "",
            address: "",
            cac: "",
            service: "",
            shopName: "",
            image: null
          };
    },
    SET_RESPONSE: (state, action: PayloadAction<any>) => {
      state.response = action.payload;
    },
  },
});

export const { SET_SHOPOWNER, SET_RESPONSE } = shopOwnerSlice.actions;

export default shopOwnerSlice.reducer;
