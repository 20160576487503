import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface Root {
  user: User;
  permissions: string[];
}

interface User {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  gender: string;
  role: string;
  lga: string;
  areaUnit: string;
}

const initialState: Root = {
  user: {
    areaUnit: "",
    firstName: "",
    lastName: "",
    lga: "",
    gender: "",
    phoneNumber: "",
    role: "",
  },
  permissions: [],
};

export const addUserSlice = createSlice({
  name: "manageUser",
  initialState,
  reducers: {
    SET_ADD_USER: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    SET_PERMISSION: (state, action: PayloadAction<string[]>) => {
      state.permissions = action.payload;
    },
  },
});

export const { SET_ADD_USER, SET_PERMISSION } = addUserSlice.actions;

export default addUserSlice.reducer;
