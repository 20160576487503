import React, { useEffect, useState } from "react";

// use countdown hook
const useCountdown = (targetDate: string) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

const DateTimeDisplay = ({
  value,
  type,
  isDanger,
}: {
  value: string;
  type: string;
  isDanger: boolean;
}) => {
  return (
    <div className="mx-2 text-center">
      <p
        className={`border border-primary-300 rounded-md p-2 ${
          isDanger ? "text-red-600" : "m-0"
        }`}
      >
        {value}
      </p>
      <span
        className={`text-primary-300 text-sm 2xl:text-base ${
          isDanger ? " text-red-600" : "m-0"
        }`}
      >
        {type}
      </span>
    </div>
  );
};

export const ExpiredNotice = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="p-3 bg-secondary w-full self-center">
        <span className="text-red-500 uppercase text-center font-Montserrat">
          Subscription Expired!!!
        </span>
      </div>
    </div>
  );
};

const ShowCounter = ({
  days,
  hours,
  minutes,
  seconds,
}: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  return (
    <div className="flex justify-end mb-6">
      <div className="p-3 flex flex-row justify-center items-center w-fit bg-secondary">
        <DateTimeDisplay
          value={days.toString()}
          type={"Days"}
          isDanger={days <= 3}
        />
        {/* <p className='text-primary-300'>:</p> */}
        <DateTimeDisplay
          value={hours.toString()}
          type={"Hours"}
          isDanger={false}
        />
        {/* <p className='text-primary-300'>:</p> */}
        <DateTimeDisplay
          value={minutes.toString()}
          type={"Minutes"}
          isDanger={false}
        />
        {/* <p className='text-primary-300'>:</p> */}
        <DateTimeDisplay
          value={seconds.toString()}
          type={"Seconds"}
          isDanger={false}
        />
      </div>
    </div>
  );
};

const Timer = ({ targetDate }: { targetDate: string }) => {
  // console.log('this is target date', targetDate)
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

const Days = ({ targetDate }: { targetDate: string }) => {
  const TargetDay = useCountdown(targetDate);
  return TargetDay;
};

export default Timer;
