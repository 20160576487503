import createTheme from "@mui/material/styles/createTheme";

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#532B81",
      light: "rgba(255, 80, 0, 0.5)",
    },
  },
  typography: {
    fontFamily: ["'Nunito'", "sans-serif"].join(","),
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 15,
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: "#1900FE",
            color: "white",
          },
          "&:focus": {
            backgroundColor: "#1900FE",
            color: "white",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          boxShadow: "4px 6px 38px -13px rgba(0,0,0,0.75)",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          boxShadow: "4px 6px 38px -13px rgba(0,0,0,0.75)",
        },
      },
    },
    // MuiButton: {
    //     defaultProps: {
    //         disableElevation: true
    //     },
    //     styleOverrides: {
    //         root: {
    //             textTransform: 'capitalize',
    //             borderRadius: 5
    //         }
    //     }
    // },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          [`& fieldset`]: {
            borderRadius: 10,
            // border: '1px solid  #1900FE'
          },
        },
      },
    },
  },
});
