import { MdOutlineDashboard } from "react-icons/md";
import { RiFileList2Line } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { IoWalletOutline } from "react-icons/io5";

export const ROUTES = [
  {
    name: "Dashboard",
    path: "/shop-owner/dashboard",
    icon: MdOutlineDashboard,
  },
  {
    name: "Wallet",
    path: "/shop-owner/wallet",
    icon: IoWalletOutline,
  },
  {
    name: "History",
    path: "/history",
    icon: RiFileList2Line,
    subItems: [
      {
        name: "Pending Payments",
        path: "/shop-owner/pending-payments",
      },
      {
        name: "Total Payments",
        path: "/shop-owner/total-payments",
      }
    ]
  },
  {
    name: "My Settings",
    path: "/shop-owner/settings",
    icon: FiSettings,
  },
];
