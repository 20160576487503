import React from "react";
import AModal from "@mui/material/Modal";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "@mui/material";
import { FormInput } from "../../../components/formInput";
import { LGAInfo } from "../../../config/formikServices";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "../../../store/appSlice";
import { updateLGA } from "../../../api/lga";

const EditLGA = ({
  show,
  setLGA,
  hide,
  details,
}: {
  setLGA: React.Dispatch<React.SetStateAction<any[]>>;
  details: any;
  show: boolean;
  hide: () => void;
}) => {
  const formik = LGAInfo(
    (detail) => {
      setLGA((prev) => {
        const data: any[] = [];
        prev.forEach((element, index) => {
          if (element?._id === details?._id) {
            data.push(detail);
          } else {
            data.push(element);
          }
        });
        hide();
        return data;
      });
    },
    "edit",
    details?._id
  );
  React.useEffect(() => {
    formik.setValues({ lga: details?.name || "" });
  }, [details]);

  return (
    <AModal
      open={show}
      onClose={hide}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <div className="w-[90%] sm:w-[80%] md:w-[60%] lg:w-[40%] max-h-full bg-white px-3 py-4 rounded-lg">
        <div className="w-full flex justify-end items-center mb-3">
          <AiOutlineClose
            size={23}
            className="text-primary-300 cursor-pointer"
            onClick={hide}
          />
        </div>
        <h2 className="font-Grotesk text-[25px] text-primary text-center font-bold mt-3 mb-6">
          Edit Local Government
        </h2>
        <form
          className="w-full"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <FormInput
            name="lga"
            label="Enter Local Government Area"
            placeholder="Enter LGA"
            formik={formik}
            type="text"
            color="dark"
          />
          <Button fullWidth variant="contained" type="submit">
            Edit LGA
          </Button>
        </form>
      </div>
    </AModal>
  );
};

export default EditLGA;
