import axios from "axios";
import { store } from "../store";
import { refreshToken } from "./auth";
import { BASE_URL } from "./config";

export const getActivityByAreaUnit = async (
    data: {
        id: string;
        page: number;
    },
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "get",
    url: `${BASE_URL}/activities/area-unit/${data.id}?page=${data.page}`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.access_token}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    if (err?.response?.data.status === 401) {
      refreshToken(
        async (arg) => {
          const updatedConfig = {
            ...config,
            headers: { Authorization: `Bearer ${arg.data.access_token}` },
          };
          const response = await axios(updatedConfig);
          execute(response.data);
        },
        (errorNew) => {
          error(errorNew);
        }
      );
    } else {
      err?.response?.data?.message &&
        error(
          err?.response?.data?.message || "Something went wrong. Try again."
        );
    }
  }
};

export const getAllActivity = async (
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${BASE_URL}/activities`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.access_token}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    if (err?.response?.data.status === 401) {
      refreshToken(
        async (arg) => {
          const updatedConfig = {
            ...config,
            headers: { Authorization: `Bearer ${arg.data.access_token}` },
          };
          const response = await axios(updatedConfig);
          execute(response.data);
        },
        (errorNew) => {
          error(errorNew);
        }
      );
    } else {
      err?.response?.data?.message &&
        error(
          err?.response?.data?.message || "Something went wrong. Try again."
        );
    }
  }
};
