import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../store";

export const ProtectedRoute = ({
  type,
  children,
}: {
  type: "admin" | "chairman" | "artisan";
  children: JSX.Element;
}) => {
  const navigate = useNavigate();
  const { loggedIn, role, access_token } = useSelector(
    (state: RootState) => state.auth
  );

  React.useEffect(() => {
    if (!loggedIn && !access_token) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return role === type ? children : <>{navigate("/login")}</>;
  // return  children 
};
