import React from "react";
import AdminDashboardLayout from "../layout/adminDashboardLayout";
import { Button, Menu, MenuItem } from "@mui/material";
import { FormInput } from "../../../components/formInput";
import { AreaUnitInfo } from "../../../config/formikServices";
import { BiSearch } from "react-icons/bi";
import SelectInput from "../../../components/selectInput";
import EditAreaUnit from "./editAreaUnit";
import { BsThreeDots } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { getAllLGA } from "../../../api/lga";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import { DelayFor, capitalize } from "../../../utils/helpers";
import { deleteAreaUnit, getAreaUnit } from "../../../api/area_unit";
import moment from "moment";
import { getAllServices } from "../../../api/services";

const AreaUnit = () => {
  const dispatch = useDispatch();
  const [lga, setLGA] = React.useState<any[]>([]);
  const [area_unit, setAreaUnit] = React.useState<any[]>([]);
  const [showEdit, setShowEdit] = React.useState<any>(null);
  const [id, setId] = React.useState<any>(null);
  const [search, setSearch] = React.useState<string>("");
  const formik = AreaUnitInfo((details) =>
    setAreaUnit([...area_unit, details])
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setAnchorEl(event.currentTarget);
    setId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const fetchData = () => {
    dispatch(SET_LOADER(true));
    getAreaUnit(
      (response) => {
        dispatch(SET_LOADER(false));
        setAreaUnit(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      },
      { name: search }
    );
  };

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getAllLGA(
      (response) => {
        dispatch(SET_LOADER(false));
        setLGA(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
    // eslint-disable-next-line
  }, []);

  return (
    <AdminDashboardLayout className={`px-6 py-5 relative h-full`}>
      <EditAreaUnit
        show={showEdit}
        setAreaUnit={setAreaUnit}
        details={id}
        lga={lga}
        hide={() => setShowEdit(false)}
      />
      {/* TABLE MENU ITEM */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setShowEdit(true);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            dispatch(SET_LOADER(true));
            deleteAreaUnit(
              { id: id?._id },
              (response) => {
                dispatch(SET_LOADER(false));
                setAreaUnit(area_unit.filter((key) => key._id !== id?._id));
              },
              (error) => {
                dispatch(SET_LOADER(false));
                dispatch(
                  SET_MODAL({
                    isError: true,
                    message: error,
                    show: true,
                    title: "Error!",
                  })
                );
                DelayFor(() => {
                  dispatch(
                    SET_MODAL({
                      isError: false,
                      message: error,
                      show: false,
                      title: "",
                    })
                  );
                }, 3000);
              }
            );
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      <div className="flex flex-col md:flex-row justify-between items-start">
        <div className="md:w-[40%] mb-5 w-full py-3 px-6 bg-white rounded-lg flex flex-col items-center">
          <h2 className="font-Grotesk text-[25px] text-primary font-bold mb-6">
            Create Area Unit
          </h2>
          <form
            className="min-w-[250px] !w-full !max-w-[450px]"
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <FormInput
              name="areaUnit"
              label="Enter Area Unit"
              placeholder="Enter area unit"
              formik={formik}
              type="text"
              color="dark"
            />
            <SelectInput
              label="Select LGA"
              name="lga"
              formik={formik}
              data={lga.map((item) => ({ id: item?._id, name: item.name }))}
              placeholder="Select a local government for the area unit"
            />
            <Button fullWidth variant="contained" type="submit">
              Create Area Unit
            </Button>
          </form>
        </div>
        <div className="md:w-[55%] w-full bg-white rounded-lg">
          <div className="w-full max-w-[100%]">
            <div className="w-full py-4 rounded-lg mb-2 h-full bg-white">
              <div className="px-6 w-full pb-3">
                <div className="w-[100%] rounded-lg overflow-hidden border flex items-center h-[35px]">
                  <input
                    type="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="w-[87%] px-3 py-2 text-[15px] h-full outline-none"
                  />
                  <div
                    onClick={fetchData}
                    className="w-[13%] cursor-pointer h-full flex justify-center items-center bg-primary-300"
                  >
                    <BiSearch size={23} color="white" />
                  </div>
                </div>
              </div>
              <div className="overflow-auto w-full max-w-full">
                <table className="table-auto sm:min-w-[140%] min-w-[250%] lg:min-w-[120%]">
                  <thead className="sticky top-0">
                    <tr className="bg-[#F4F6F8]">
                      <th
                        scope="col"
                        className="pl-6 pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]"
                      >
                        Name
                      </th>
                      <th className="pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]">
                        LGA
                      </th>
                      <th className="pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]">
                        Date
                      </th>
                      <th className="pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]">
                        Created By
                      </th>
                      <th className="px-3 pt-3 pb-2 text-center font-Grotesk font-normal text-[#626262]">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border-b border-b-[rgba(145,158,171,0.24)] w-full">
                    {area_unit.map((item) => (
                      <tr key={item._id}>
                        <th
                          scope="col"
                          className="py-3 text-left font-Grotesk font-normal text-black"
                        >
                          <p className="font-medium text-black ml-6">
                            {item?.name
                              ?.split(" ")
                              ?.map((data?: string) =>
                                data ? capitalize(data) : ""
                              )
                              ?.join(" ")}
                          </p>
                        </th>
                        <th className="py-5 text-left font-Grotesk font-normal text-black">
                          {item?.lga?.name
                            ?.split(" ")
                            ?.map((data?: string) =>
                              data ? capitalize(data) : ""
                            )
                            ?.join(" ")}
                        </th>
                        <th className="py-5 text-left font-Grotesk font-normal text-black">
                          {moment(item.created_at).format("DD MMM YYYY")}
                        </th>
                        <th className="py-5 text-left font-Grotesk font-normal text-black">
                          {item?.created_by
                            ?.split(" ")
                            ?.map((data?: string) =>
                              data ? capitalize(data) : ""
                            )
                            ?.join(" ")}
                        </th>
                        <th className="py-5 text-center font-Grotesk font-normal flex justify-center items-center">
                          <button onClick={(e) => handleClick(e, item)}>
                            <BsThreeDots size={25} className="cursor-pointer" />
                          </button>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <div className="w-full px-6 flex justify-end items-end mt-3 ">
                <Pagination
                  count={5}
                  page={page}
                  // color="secondary"
                  onChange={(event, value: number) => setPage(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: HiArrowLongLeft,
                        next: HiArrowLongRight,
                      }}
                      {...item}
                    />
                  )}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </AdminDashboardLayout>
  );
};

export default AreaUnit;
