import React, { useContext } from "react";
import Box from "@mui/material/Box";
import AModal from "@mui/material/Modal";
import { SuccessIcon } from "../assets/icons/icons";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { SET_LOADER, SET_MODAL, SET_PAYMENT_MODAL } from "../store/appSlice";
import { createArtisan } from "../api/user";
import { SET_RESPONSE } from "../store/artisanSlice";
import { DelayFor } from "../utils/helpers";
import { IoMdClose } from "react-icons/io";
import { subscribeArtisan } from "../api/wallet";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  boxShadow: 0,
  m: "0 auto",
  p: 1,
};

const ConfirmationModal = () => {
  const { confirmModal } = useSelector((state: RootState) => state.app);
  const { profile } = useSelector((state: RootState) => state.auth);
  const { artisan } = useSelector((state: RootState) => state.artisan);
  const dispatch = useDispatch();

  const handleClose = () =>
    dispatch(
      SET_PAYMENT_MODAL({
        ...confirmModal,
        show: false,
        handleNext: () => {},
        duration: 0,
      })
    );

  const handlePayment = () => {
    createArtisan(
      {
        firstName: artisan.firstName,
        lastName: artisan.lastName,
        gender: artisan.gender,
        phoneNumber: artisan.phoneNumber,
        area_unit: profile?.area_unit?._id,
        address: artisan.address,
        cac: artisan.cac,
        image: artisan.image,
        service: artisan.service,
      },
      (response) => {
        if (response?.error) return;
        dispatch(SET_RESPONSE(response.data));
        subscribeArtisan(
          { user_id: response?.data?._id, duration: confirmModal.duration },
          (response1) => {
            dispatch(SET_LOADER(false));
            dispatch(
              SET_MODAL({
                isError: false,
                message: response.message,
                show: true,
                title: "Success!",
              })
            );
            DelayFor(() => {
              dispatch(
                SET_MODAL({
                  isError: false,
                  message: "",
                  show: false,
                  title: "",
                })
              );
            }, 3000);
            handleClose();
            confirmModal.handleNext();
          },
          (error1) => {
            dispatch(SET_LOADER(false));
            dispatch(
              SET_MODAL({
                isError: true,
                message: error1,
                show: true,
                title: "Error!",
              })
            );
            DelayFor(() => {
              dispatch(
                SET_MODAL({
                  isError: false,
                  message: error1,
                  show: false,
                  title: "",
                })
              );
            }, 3000);
          }
        );
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  };

  return (
    <AModal
      open={confirmModal.show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="AModal p-3">
        <div className="flex justify-between items-center py-2">
          <p className="text-xl text-gray-600 font-semibold ">
            Confirm Payment
          </p>
          <span className="cursor-pointer" onClick={handleClose}>
            <IoMdClose className="text-xl text-gray-300 hover:text-gray-500" />
          </span>
        </div>
        <p className="text-gray-500 text-lg font-medium border-t border-b border-gray-200 py-4">
          Please confirm Artisan payment
        </p>
        <div className="text-right py-3">
          <button
            className="bg-blue-300 hover:bg-blue-400 py-1 px-2 rounded text-gray-900 text-base hover:text-gray-600 font-semibold mx-2"
            onClick={handlePayment}
          >
            Confirm
          </button>
          <button
            className="bg-gray-300 hover:bg-gray-200 py-1 px-2 rounded text-gray-900 text-base hover:text-gray-600 font-semibold"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </Box>
    </AModal>
  );
};

export default ConfirmationModal;
