import axios from "axios";
import { store } from "../store";
import { refreshToken } from "./auth";
import { BASE_URL } from "./config";

export const getBalance = async (
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "get",
    url: `${BASE_URL}/wallet/balance`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.access_token}`,
    },
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    if (err?.response?.data.status === 401) {
      refreshToken(
        async (arg) => {
          const updatedConfig = {
            ...config,
            headers: { Authorization: `Bearer ${arg.data.access_token}` },
          };
          const response = await axios(updatedConfig);
          execute(response.data);
        },
        (errorNew) => {
          error(errorNew);
        }
      );
    } else {
      err?.response?.data?.message &&
        error(
          err?.response?.data?.message || "Something went wrong. Try again."
        );
    }
  }
};

export const deposit = async (
  data: {
    amount: number;
    callback_url: string;
  },
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "post",
    url: `${BASE_URL}/wallet/deposit`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.access_token}`,
    },
    data
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    if (err?.response?.data.status === 401) {
      refreshToken(
        async (arg) => {
          const updatedConfig = {
            ...config,
            headers: { Authorization: `Bearer ${arg.data.access_token}` },
          };
          const response = await axios(updatedConfig);
          execute(response.data);
        },
        (errorNew) => {
          error(errorNew);
        }
      );
    } else {
      err?.response?.data?.message &&
        error(
          err?.response?.data?.message || "Something went wrong. Try again."
        );
    }
  }
};


export const depositInvoice = async (
  data: {
    amount: number;
    invoice: string;
  },
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "post",
    url: `${BASE_URL}/wallet/deposit-cbs`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.access_token}`,
    },
    data
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    if (err?.response?.data.status === 401) {
      refreshToken(
        async (arg) => {
          const updatedConfig = {
            ...config,
            headers: { Authorization: `Bearer ${arg.data.access_token}` },
          };
          const response = await axios(updatedConfig);
          execute(response.data);
        },
        (errorNew) => {
          error(errorNew);
        }
      );
    } else {
      err?.response?.data?.message &&
        error(
          err?.response?.data?.message || "Something went wrong. Try again."
        );
    }
  }
};


export const subscribeArtisan = async (
  data: {
    user_id: string;
    duration: number;
  },
  execute: (e: any) => void,
  error: (e: string) => void
) => {
  var config = {
    method: "post",
    url: `${BASE_URL}/users/subcribe`,
    headers: {
      Authorization: `Bearer ${store.getState().auth.access_token}`,
    },
    data
  };

  try {
    const response = await axios(config);
    execute(response.data);
  } catch (err: any) {
    if (err?.response?.data.status === 401) {
      refreshToken(
        async (arg) => {
          const updatedConfig = {
            ...config,
            headers: { Authorization: `Bearer ${arg.data.access_token}` },
          };
          const response = await axios(updatedConfig);
          execute(response.data);
        },
        (errorNew) => {
          error(errorNew);
        }
      );
    } else {
      err?.response?.data?.message &&
        error(
          err?.response?.data?.message || "Something went wrong. Try again."
        );
    }
  }
};
