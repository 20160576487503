import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import back from "../assets/icons/back.svg";
import { Pagination, PaginationItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { getArtisans } from "../api/user";
import { SET_LOADER, SET_MODAL } from "../store/appSlice";
import { DelayFor } from "../utils/helpers";
import { getAllServices } from "../api/services";
import { getAreaUnitByLGA } from "../api/area_unit";
import ArtisanCard from "../components/artisanCard";

const Result = () => {
  const dispatch = useDispatch();
  const { lga } = useParams();
  const navigate = useNavigate();
  const [selectedService, setSelectedService] = React.useState<string>("");
  const [selectedAreaUnit, setSelectedAreaUnit] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(1);
  const [artisans, setArtisans] = React.useState<any>(null);
  const [services, setServices] = React.useState<any[]>([]);
  const [area_unit, setAreaUnit] = React.useState<any[]>([]);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getAreaUnitByLGA(
      lga || "",
      (response) => {
        dispatch(SET_LOADER(false));
        setAreaUnit(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }





    );
    // eslint-disable-next-line
  }, [lga]);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getAllServices(
      (response) => {
        dispatch(SET_LOADER(false));
        setServices(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getArtisans(
      {
        page,
        area_unit:
          selectedAreaUnit.length > 0 &&
          area_unit.find((item) => item._id === selectedAreaUnit)
            ? selectedAreaUnit
            : null,
        service:
          selectedService.length > 0 &&
          services.find((item) => item._id === selectedService)
            ? selectedService
            : null,
        lga,
        is_active: true,
      },
      (response) => {
        dispatch(SET_LOADER(false));
        setArtisans(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
    // eslint-disable-next-line
  }, [page, lga, selectedAreaUnit, selectedService]);
  return (
    <div className="bg-primary-100 w-full h-screen overflow-y-auto">
      <div className="py-3 w-[90%] h-full mx-auto">
        <div className="flex justify-between items-center mb-3">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <img src={back} alt="" className="mr-2" /> <span>Back</span>
          </div>
          <div>
            <select
              value={selectedService}
              onChange={(e) => setSelectedService(e.target.value)}
              className="border rounded-md border-gray-400 py-2 px-2 min-w-[120px] m-1 outline-none"
            >
              <option value="">Services</option>
              {services.map((data, idx) => (
                <option value={data._id} key={idx}>
                  {data.name}
                </option>
              ))}
            </select>
            <select
              value={selectedAreaUnit}
              onChange={(e) => setSelectedAreaUnit(e.target.value)}
              className="border rounded-md border-gray-400 py-2 px-2 min-w-[120px] m-1 outline-none"
            >
              <option value="">Area Unit</option>
              {area_unit.map((item, idx) => (
                <option value={item._id} key={idx}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {!artisans || artisans?.docs?.length === 0 ? (
          <div className="flex h-[90%] justify-center items-center">
            <h2 className="text-[30px]">No artisans available</h2>
          </div>
        ) : (
          <>
            <div className="flex justify-between flex-row flex-wrap">
              {artisans?.docs.map((item: any) => (
                <div
                  key={item._id}
                  className="mb-5 w-[48%] md:w-[32%] lg:w-[24%]"
                >
                  <Link to={`${item._id}`}>
                    <ArtisanCard detail={item} showExpiry={false} />
                  </Link>
                </div>
              ))}
            </div>
            <div className="w-full px-6 flex justify-end items-end mt-3 ">
              <Pagination
                count={artisans?.totalPages || 0}
                page={page}
                // color="secondary"
                onChange={(event, value: number) => setPage(value)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{
                      previous: HiArrowLongLeft,
                      next: HiArrowLongRight,
                    }}
                    {...item}
                  />
                )}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Result;
