import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  capitalize,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserByID } from "../api/user";
import { SET_LOADER, SET_MODAL } from "../store/appSlice";
import { DelayFor } from "../utils/helpers";
import Nav from "../components/nav";
import { MdLocationOn, MdOutlineWorkOutline } from "react-icons/md";
import { IoIosCall } from "react-icons/io";

const ResultDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [details, setDetails] = React.useState<any>(null);
  //   const []

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getUserByID(
      params?.id || "",
      (response) => {
        dispatch(SET_LOADER(false));
        setDetails(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  }, [params?.id]);

  return (
    <div>
      <Nav />
      {/* <Dialog open={show_number} onClose={() => setShowNumber(false)}>
        <DialogTitle>
          {phoneDetails?.User.first_name + " " + phoneDetails?.User.last_name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Call{" "}
            {phoneDetails?.User.first_name + " " + phoneDetails?.User.last_name}{" "}
            on {phoneDetails?.User.phone_number}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNumber(false)} variant="outlined">
            Close
          </Button>
          <Button autoFocus variant="contained">
            <a
              className="p-1 text-sm"
              href={`tel:${phoneDetails?.User.phone_number}`}
            >
              Call
            </a>
          </Button>
        </DialogActions>
      </Dialog> */}
      <div className="description px-5 md:px-10 pt-10 md:pt-24">
        <div className="description__one block md:grid grid-cols-2 2xl:grid-cols-3 ">
          <div className="description__one-img w-full bg-grey-200 md:w-56 xl:w-72 md:h-44 md:mx-auto">
            <img src={details?.image?.url} alt="" className="w-full h-full" />
          </div>

          <div className="description__one-info w-full ">
            <h4 className="text-primary-300 text-[25px] font-bold my-2">
              {`${details?.first_name || ""} ${details?.last_name || ""}`}
            </h4>
            <span className="flex items-center mb-2">
              <MdLocationOn className="text-primary-300 mr-2" />
              {details?.address}
            </span>
            <span className="flex items-center mb-2">
              <MdOutlineWorkOutline className="text-primary-300 mr-2 rounded" />
              {details?.service?.name}
            </span>
            <span className="flex items-center mb-2">
              <IoIosCall className="text-primary-300 mr-2" />
              {details?.phone_number}
            </span>

            <div className="flex space-x-3 w-full md:w-1/2">
              <Button
                onClick={() =>
                  (window.location.href = `tel:${details?.phone_number}`)
                }
                className="buttons-two"
                variant="contained"
              >
                Call Artisan
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultDetail;
