import React from "react";
import DashboardLayout from "../layout/dashboardLayout";
import { Button } from "@mui/material";
import ArtisanCard from "../../../components/artisanCard";
import AddShopOwner from "./addShopOwner";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "../../../store";
import { DelayFor, capitalize } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import { chairmanOverview, getArtisans, getShopOwners } from "../../../api/user";
import { SET_SHOPOWNER } from "../../../store/shopOwnerSlice";
import { SET_ARTISAN } from "../../../store/artisanSlice";
import ConfirmationModal from "../../../components/ConfirmPaymentModal";
// import AddShopOwner from "./addShopOwner";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [showShopOwner, setShowShopOwner] = React.useState<boolean>(false);
  const [shopOwner, setShopOwner] = React.useState<any>(null);
  const [dashboard, setDashboard] = React.useState<any>(null);
  const { profile } = useSelector((state: RootState) => state.auth);
// console.log(profile)
  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    chairmanOverview(
      (response) => {
        setDashboard(response.data);
        dispatch(SET_LOADER(false));
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    if (profile) {
      getShopOwners(
        { page: 1, area_unit: profile?.area_unit?._id },
        (response) => {
          dispatch(SET_LOADER(false));
          setShopOwner(response.data);
          // console.log(response.data)

        },
        (error) => {
          dispatch(SET_LOADER(false));
          dispatch(
            SET_MODAL({
              isError: true,
              message: error,
              show: true,
              title: "Error!",
            })
          );
          DelayFor(() => {
            dispatch(
              SET_MODAL({
                isError: false,
                message: error,
                show: false,
                title: "",
              })
            );
          }, 3000);
        }
      );
    }
    // eslint-disable-next-line
  }, [profile]);
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <>
        {showShopOwner && (
          <AddShopOwner show={showShopOwner} hide={() => setShowShopOwner(false)} />
        )}
      </>

      <div className="w-full px-6 py-6 bg-primary-300">
        <p className="font-bold text-xl text-white">
          Hello, {capitalize(profile?.first_name || "")}!
        </p>
        <p className="font-bold text-sm text-white mt-2">
          Looking for a workman today?
        </p>
      </div>

      <div className="px-6 py-6 relative w-full">
        <div className="w-full flex flex-row items-center flex-wrap mt-5">
          <div
            onClick={() => navigate("/chairman/dashboard/shop-owners")}
            className="w-full sm:w-[48%] lg:w-[30%] sm:mr-[4%] lg:mr-[3%] py-4 px-7 rounded-lg bg-primary-300 mb-4 cursor-pointer"
          >
            <h3 className="text-grey font-DMSans text-[15px] text-white">
              Total Shop Owners
            </h3>
            <h3 className="text-primary font-DMSans font-extrabold text-[30px] mb-3 text-white">
              {dashboard?.num_of_artisan?.toLocaleString() || 0}
            </h3>
          </div>
          <div
            onClick={() => navigate("/chairman/dashboard/shop-owners")}
            className="w-full sm:w-[48%] lg:w-[30%] py-4 px-7 rounded-lg bg-primary-300 mb-4 cursor-pointer"
          >
            <h3 className="text-grey font-DMSans text-[15px] text-white">
              Active Shop Owners
            </h3>
            <h3 className="text-primary font-DMSans font-extrabold text-[30px] mb-3 text-white">
              {dashboard?.num_of_active_artisan?.toLocaleString() || 0}
            </h3>
          </div>
        </div>
        <div className="w-full flex justify-end items-center mb-10">
          <Button
            variant="contained"
            onClick={() => {
              dispatch(SET_ARTISAN(null));
              setShowShopOwner(true);
            }}
          >
            Add Shop Owner
          </Button>
        </div>

        <div className="w-full flex justify-between items-center mb-3">
          <p className="font-extrabold text-2xl">Shop Owners</p>
          <Link to="/chairman/dashboard/shop-owners" className="">
            <p className="text-primary-300 cursor-pointer">View All</p>
          </Link>
        </div>
        <div className="flex flex-row overflow-x-auto no-scrollbar flex-nowrap mt-3 [&>*]:mr-4 last:mr-0">
          {shopOwner?.docs.map((item: any) => (
            <div key={item._id} className="min-w-[200px]">
              {!item.owner ? (<div className="border py-2 rounded-md ">
                <h2 className="text-gray-600 text-3xl text-center">{item.name}</h2>
                <p className="text-center">shop has no owner</p> 
                </div>) : 
              <Link to={`/chairman/dashboard/artisans/${item.owner?._id}`}>
                <ArtisanCard detail={item.owner} />
              </Link>
               }
            </div>
          ))}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
