import React from "react";
import "./App.css";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { RootState } from "./store";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/landingPage";
import Login from "./pages/auth/login";
import ForgotPassword from "./pages/auth/forgotPassword";
import Modal from "./components/modal";
import Dashboard from "./pages/chairman/dashboard/dashboard";
import Artisans from "./pages/shop_owner/dashboard/artisans";
import Detail from "./pages/chairman/dashboard/detail";
import History from "./pages/chairman/history";
import Settings from "./pages/chairman/settings";
import Wallet from "./pages/chairman/wallet";
import AdminDashboard from "./pages/admin/dashboard";
import ManageUsers from "./pages/admin/manage-users";
import ViewUser from "./pages/admin/manage-users/viewUser";
import AdminTransactions from "./pages/admin/transactions";
import AdminSettings from "./pages/admin/settings";
import ManageLGA from "./pages/admin/manage-lga";
import AreaUnit from "./pages/admin/area-unit";
import ManageServices from "./pages/admin/manage-services";
import { ProtectedRoute } from "./components/protectedRoute";
import { useDispatch } from "react-redux";
import { SET_LOADER } from "./store/appSlice";
import { getUserProfile } from "./api/user";
import { SET_PROFILE } from "./store/authSlice";
import Result from "./pages/result";
import ResultDetail from "./pages/resultDetail";
import Transactions from "./pages/chairman/pending-payment";
import ShopOwners from "./pages/chairman/dashboard/shop_owners";
import ShopOwnerDashboard from "./pages/shop_owner/dashboard/dashboard";
import TotalPayments from "./pages/chairman/total-payment";
import SOTotalPayments from "./pages/shop_owner/total-payment";
import SOPendingPayemts from "./pages/shop_owner/pending-payment";
import ShopOwnerWallet from "./pages/shop_owner/wallet";
import ShopOwnerSettings from "./pages/shop_owner/settings";
import ShopOwnerDetails from "./pages/shop_owner/dashboard/detail";


function App() {
  const dispatch = useDispatch();
  const { loader } = useSelector((state: RootState) => state.app);
  const { loggedIn, access_token } = useSelector(
    (state: RootState) => state.auth
  );

  React.useEffect(() => {
    if (loggedIn && access_token) {
      dispatch(SET_LOADER(true));
      getUserProfile(
        (response) => {
          dispatch(SET_LOADER(false));
          console.log(response.data);
          dispatch(SET_PROFILE(response.data));
        },
        (error) => {
          dispatch(SET_LOADER(false));
        }
      );
    }
  }, [loggedIn]);
  return (
    <div>
      <Routes>
        {/* CHAIRMAN ROUTES */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/result/:lga" element={<Result />} />
        <Route path="/result/:lga/:id" element={<ResultDetail />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/chairman/dashboard"
          element={
            <ProtectedRoute type="chairman">
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chairman/dashboard/artisans"
          element={
            <ProtectedRoute type="chairman">
              <Artisans />
            </ProtectedRoute>
          }
        />

          <Route
          path="/chairman/dashboard/shop-owners"

          element={
            <ProtectedRoute type="chairman">
              <ShopOwners />
            </ProtectedRoute>
          }
        />
       
        <Route
          path="chairman/dashboard/artisans/:id"
          element={
            <ProtectedRoute type="chairman">
              <Detail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chairman/total-payments"
          element={
            <ProtectedRoute type="chairman">
              <TotalPayments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chairman/settings"
          element={
            <ProtectedRoute type="chairman">
              <Settings />
            </ProtectedRoute>
          }
        />
         <Route
          path="/chairman/pending-payments"

          element={
            <ProtectedRoute type="chairman">
              <Transactions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chairman/wallet"
          element={
            <ProtectedRoute type="chairman">
              <Wallet />
            </ProtectedRoute>
          }
        />

        {/* ADMIN ROUTES */}

        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoute type="admin">
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/transactions"
          element={
            <ProtectedRoute type="admin">
              <AdminTransactions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/settings"
          element={
            <ProtectedRoute type="admin">
              <AdminSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/lga"
          element={
            <ProtectedRoute type="admin">
              <ManageLGA />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/area-unit"
          element={
            <ProtectedRoute type="admin">
              <AreaUnit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/user"
          element={
            <ProtectedRoute type="admin">
              <ManageUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/services"
          element={
            <ProtectedRoute type="admin">
              <ManageServices />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/user/:id"
          element={
            <ProtectedRoute type="admin">
              <ViewUser />
            </ProtectedRoute>
          }
        />

        {/* shop owner routes */}

         <Route

          path="/shop-owner/dashboard"
          element={
            <ProtectedRoute type="artisan">
              <ShopOwnerDashboard />
            </ProtectedRoute>
          }
        />

          <Route
          path="/shop-owner/dashboard/artisans"
          element={
            <ProtectedRoute type="artisan">
              <Artisans />
            </ProtectedRoute>
          }
        />
           <Route

          path="/shop-owner/total-payments"
          element={
            <ProtectedRoute type="artisan">
              <SOTotalPayments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/shop-owner/pending-payments"
          element={
            <ProtectedRoute type="artisan">
              <SOPendingPayemts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/shop-owner/wallet"
          element={
            <ProtectedRoute type="artisan">
              <ShopOwnerWallet />
            </ProtectedRoute>
          }
        />
             <Route
          path="/shop-owner/settings"
          element={
            <ProtectedRoute type="artisan">
              <ShopOwnerSettings />
            </ProtectedRoute>
          }
        />
         
         <Route
          path="shop-owner/dashboard/artisans/:id"
          element={
            <ProtectedRoute type="artisan">
              <ShopOwnerDetails />
            </ProtectedRoute>
          }
        />
      </Routes>

      <Modal />
      {loader && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
          className="w-full h-full fixed top-0 left-0 flex justify-center items-center z-[3000]"
        >
          <div className="px-3 bg-white rounded-lg flex flex-col justify-center items-center overflow-y-auto py-3">
            <CircularProgress size={25} />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
