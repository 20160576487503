import { IconButton, Button } from "@mui/material";
import React from "react";
import image from "../assets/imgs/image";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const Nav = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = React.useState(false);
  return (
    <>
      <nav className="relative bg-primary-100 flex justify-between items-center px-5 py-2 lg:px-10">
        <div className="logo">
          <Link
            to="/"
            className="text-primary-300 font-semibold block w-fit px-2"
          >
            <img src={image.logo} className="mx-auto w-8" alt="" />
            {/* <p className="whitespace-nowrap">Riv Artisan</p> */}
          </Link>
        </div>
        {/* <SearchBar /> */}
        <ul
          className={`absolute sm:relative top-0 right-0 left-0  w-full  bg-white h-fit sm:h-0 py-12 sm:py-0 sm:w-fit ${
            showMenu ? "flex" : "hidden"
          } flex-col sm:flex-row items-center justify-center sm:flex sm:space-x-3 z-50`}
        >
          <div className="absolute sm:hidden  top-0 right-0">
            <IconButton onClick={() => setShowMenu(false)}>
              <AiOutlineClose className="text-primary-300" />
            </IconButton>
          </div>

          <li className="my-3 lg:inline lg:mx-3 w-fit">
            <Button variant="contained" onClick={() => navigate("/login")}>
              Login
            </Button>
          </li>
        </ul>
        <div
          className="menu-toggle sm:hidden"
          onClick={() => setShowMenu(true)}
        >
          <AiOutlineMenu className="text-primary-300" />
        </div>
      </nav>
    </>
  );
};

export default Nav;
