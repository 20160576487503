import React from "react";
import AdminDashboardLayout from "../layout/adminDashboardLayout";
import { Pagination, PaginationItem } from "@mui/material";
import { GoArrowUpRight, GoArrowDownLeft } from "react-icons/go";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { useDispatch } from "react-redux";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import { getAllTransactions } from "../../../api/transactions";
import { DelayFor, capitalize } from "../../../utils/helpers";
import moment from "moment";
import { BsDashLg } from "react-icons/bs";
import { IoIosCloseCircle } from "react-icons/io";
import { getAllLGA } from "../../../api/lga";
import { getAreaUnit } from "../../../api/area_unit";

const AdminTransactions = () => {
  const dispatch = useDispatch();
  const [transaction, setTransaction] = React.useState<any>(null);
  const [start_date, setStartDate] = React.useState<string>("");
  const [selectedLGA, setSelectedLGA] = React.useState<string>("");
  const [selectedAreaUnit, setSelectedAreaUnit] = React.useState<string>("");
  const [type, setType] = React.useState<string>("");
  const [lga, setLGA] = React.useState<any[]>([]);
  const [area_unit, setAreaUnit] = React.useState<any[]>([]);
  const [end_date, setEndDate] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(1);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getAreaUnit(
      (response) => {
        dispatch(SET_LOADER(false));
        setAreaUnit(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getAllLGA(
      (response) => {
        dispatch(SET_LOADER(false));
        setLGA(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
    // eslint-disable-next-line
  }, []);

  const fetchTrans = () => {
    dispatch(SET_LOADER(true));
    getAllTransactions(
      {
        page,
        start_date: start_date.length > 0 ? start_date : null,
        end_date: end_date.length > 0 ? end_date : null,
        lga: selectedLGA.length > 0 ? selectedLGA : null,
        area_unit: selectedAreaUnit.length > 0 ? selectedAreaUnit : null,
        type: type.length > 0 ? type.toLowerCase() : null,
      },
      (response) => {
        setTransaction(response.data);
        dispatch(SET_LOADER(false));
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  };

  React.useEffect(() => {
    fetchTrans();
    // eslint-disable-next-line
  }, [page, type, selectedAreaUnit, selectedLGA]);
  return (
    <AdminDashboardLayout className={`px-6 py-5 relative h-full`}>
      <div className="w-full max-w-[100%]">
        <div className="w-full py-4 rounded-lg mb-2 h-full bg-white">
          <div className="px-6 mb-3  flex flex-col md:flex-row justify-between items-center max-w-full">
            <h2 className=" font-Grotesk font-medium text-[20px] text-black mb-2 md:mb-0">
              All Transactions
            </h2>
            <div className="flex items-center flex-wrap flex-1 justify-center md:justify-end">
              {/* 1,2,3, 4, 5 format, 6 format, 7 filters add but some are not working */}
              <select
                value={type}
                onChange={(e) => setType(e.target.value)}
                className="border rounded-md border-gray-400 py-2 px-2 min-w-[120px] mr-2 outline-none"
              >
                <option value="">Type</option>
                <option value="Debit">Debit</option>
                <option value="Credit">Credit</option>
              </select>
              <select
                value={selectedLGA}
                onChange={(e) => setSelectedLGA(e.target.value)}
                className="border rounded-md border-gray-400 py-2 px-2 min-w-[120px] mr-2 outline-none"
              >
                <option value="">LGA</option>
                {lga.map((item, idx) => (
                  <option value={item._id} key={idx}>
                    {item.name}
                  </option>
                ))}
              </select>
              <select
                value={selectedAreaUnit}
                onChange={(e) => setSelectedAreaUnit(e.target.value)}
                className="border rounded-md border-gray-400 py-2 px-2 min-w-[120px] mr-2 outline-none"
              >
                <option value="">Area Unit</option>
                {area_unit.map((item, idx) => (
                  <option value={item._id} key={idx}>
                    {item.name}
                  </option>
                ))}
              </select>
              <input
                type="date"
                className="border rounded-md border-gray-400 py-2 px-2 w-[120px]"
                value={start_date}
                onChange={(e) => setStartDate(e.target.value)}
                onBlur={fetchTrans}
                // readOnly
              />
              <BsDashLg className="mx-1" />
              <input
                type="date"
                className="border rounded-md border-gray-400 py-2 px-2 w-[120px]"
                value={end_date}
                onChange={(e) => setEndDate(e.target.value)}
                onBlur={fetchTrans}
                // readOnly
              />
              <IoIosCloseCircle
                size={24}
                className="text-red-400 cursor-pointer"
                onClick={() => {
                  setEndDate("");
                  setStartDate("");
                  setSelectedAreaUnit("");
                  setSelectedLGA("");
                  setType("");
                }}
              />
            </div>
          </div>
          <div className="overflow-auto w-full max-w-full">
            <table className="table-auto min-w-[300%] lg:min-w-full">
              <thead className="sticky top-0">
                <tr className="bg-[#F4F6F8]">
                  <th
                    scope="col"
                    className="pl-6 pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]"
                  >
                    Description
                  </th>
                  <th className="pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]">
                    Date
                  </th>
                  <th className="pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]">
                    Chairman
                  </th>
                  <th className="pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]">
                    LGA
                  </th>
                  <th className="pt-3 pb-2 text-left font-Grotesk font-normal text-[#626262]">
                    Area Unit
                  </th>
                  <th className="pt-3 pb-2 text-center font-Grotesk font-normal text-[#626262]">
                    Time
                  </th>
                  <th className="px-3 pt-3 pb-2 text-center font-Grotesk font-normal text-[#626262]">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody className="border-b border-b-[rgba(145,158,171,0.24)] w-full">
                {transaction?.docs.map((item: any) => (
                  <tr key={item._id}>
                    <th
                      scope="col"
                      className="py-3 text-left font-Grotesk font-normal text-black"
                    >
                      <div className="flex items-center ml-6">
                        <div
                          className={`p-2 ${
                            item.type === "debit"
                              ? "bg-[#E25C5C1A]"
                              : "bg-[#DDF6DE]"
                          } rounded-full`}
                        >
                          {item.type === "debit" ? (
                            <GoArrowUpRight color={"#E25C5C"} size={20} />
                          ) : (
                            <GoArrowDownLeft color={"#53D258"} size={20} />
                          )}
                        </div>
                        <div className="ml-2">
                          <p className="font-medium text-black">
                            {capitalize(item.type)} Wallet
                          </p>
                        </div>
                      </div>
                    </th>
                    <th className="py-5 text-left font-Grotesk font-normal text-black">
                      {moment(item.created_at).format("DD MMM YYYY")}
                    </th>
                    <th className="py-5 text-left font-Grotesk font-normal text-black">
                      {item?.meta?.customer?.fullName || "N/A"}
                    </th>
                    <th className="py-5 text-left font-Grotesk font-normal text-black">
                      {item?.area_unit?.lga?.name || "N/A"}
                    </th>
                    <th className="py-5 text-left font-Grotesk font-normal text-black">
                      {item?.area_unit?.name || "N/A"}
                    </th>
                    <th className="py-5 text-center font-Grotesk font-normal text-[#637381]">
                      {moment(item.created_at).format("hh:mm A")}
                    </th>
                    <th
                      className={`py-5 text-center font-Grotesk font-normal ${
                        item.type === "debit"
                          ? "text-[#E25C5C]"
                          : "text-[#53D258]"
                      }`}
                    >
                      ₦{item.amount.toLocaleString()}
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="w-full px-6 flex justify-end flex-row  items-center mt-3 ">
            {/* <h2 className="font-bold text-[17px]">Total Amount: ₦5,000</h2> */}
            <Pagination
              count={transaction?.totalPages || 1}
              page={page}
              onChange={(event, value: number) => setPage(value)}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: HiArrowLongLeft,
                    next: HiArrowLongRight,
                  }}
                  {...item}
                />
              )}
            />
          </div>
        </div>
      </div>
    </AdminDashboardLayout>
  );
};

export default AdminTransactions;
