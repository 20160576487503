import React from "react";
import Timer, { ExpiredNotice } from "./timer";
import { capitalize } from "@mui/material";
import logo from "../assets/imgs/nasarawa-logo.png";

const ArtisanCard = ({
  detail,
  showExpiry = true,
}: {
  detail: any;
  showExpiry?: boolean;
}) => {
  console.log(detail);
  return (
    <div className="w-full py-2 px-2 bg-white rounded">
      <div className="w-full h-[130px] mb-4 relative">
        <img src={logo} alt="" className="w-[30px] h-[30px] object-contain absolute right-3 top-3" />
        <img
          src={
            detail?.image?.url ||
            "https://images.unsplash.com/photo-1581578017306-7334b15283df?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGdhcmRlbmluZ3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60"
          }
          alt=""
          className="w-full h-full object-cover"
        />
      </div>
      <div className="flex flex-col justify-between items-center">
        <p className="font-Semibold text-center text-xl capitalize">
          {detail?.first_name} {detail?.last_name}
        </p>
        <p className="font-medium text-center text-md text-primary-300 capitalize">
          {detail?.service?.name || ""}
        </p>
        <p className="font-medium text-center text-md text-primary-300 capitalize">
          Area Unit: {detail?.area_unit?.name || ""}
        </p>
        <p className="font-medium text-center text-md text-primary-300 capitalize">
          LGA: {detail?.area_unit?.lga?.name || ""}
        </p>
        {/* {showExpiry && (
          <>
            {detail.sub_exipry_date ? (
              <Timer targetDate={detail.sub_exipry_date} />
            ) : (
              <ExpiredNotice />
            )}
          </>
        )} */}
      </div>
    </div>
  );
};

export default ArtisanCard;
