import React from "react";
import AdminDashboardLayout from "../layout/adminDashboardLayout";
import { useDispatch } from "react-redux";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import { DelayFor } from "../../../utils/helpers";
import { overview } from "../../../api/user";
import { Outlet, useNavigate } from "react-router-dom";
import RecentTransaction from "./RecentTransaction";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [dashboard, setDashboard] = React.useState<any>(null);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    overview(
      (response) => {
        setDashboard(response.data);
        dispatch(SET_LOADER(false));
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  return (
    <AdminDashboardLayout className={`px-6 py-5 relative h-full`}>
      <div className="w-full flex flex-row justify-between items-center flex-wrap">
        <div
          onClick={() => navigate("/admin/transactions")}
          className="w-full bg-primary-300 sm:w-[48%] lg:w-[23%] py-4 px-7 rounded-lg mb-4 cursor-pointer"
          // style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
        >
          <h3 className="text-grey font-DMSans text-[15px] text-white">
            Total Sales
          </h3>
          <h3 className="text-primary font-DMSans font-extrabold text-white text-[30px] mb-3">
            ₦{dashboard?.monthly_sales?.toLocaleString() || 0}
          </h3>
        </div>
        <div
          onClick={() => navigate("/admin/user?query=chairman")}
          className="w-full sm:w-[48%] lg:w-[23%] py-4 px-7 rounded-lg bg-primary-300 mb-4 cursor-pointer"
          // style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
        >
          <h3 className="text-grey font-DMSans text-[15px] text-white">
            Total Chairman
          </h3>
          <h3 className="text-primary font-DMSans text-white font-extrabold text-[30px] mb-3">
            {dashboard?.num_of_chairman?.toLocaleString() || 0}
          </h3>
        </div>
        <div
          onClick={() => navigate("/admin/user?query=shop")}
          className="w-full sm:w-[48%] lg:w-[23%] py-4 px-7 rounded-lg bg-primary-300 mb-4 cursor-pointer"
          // style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
        >
          <h3 className="text-grey font-DMSans text-[15px] text-white">
            Total Shop
          </h3>
          <h3 className="text-primary font-DMSans font-extrabold text-[30px] mb-3 text-white">
            {dashboard?.num_of_shop?.toLocaleString() || 0}
          </h3>
        </div>
        <div
          onClick={() => navigate("/admin/user?query=artisan")}
          className="w-full sm:w-[48%] lg:w-[23%] py-4 px-7 rounded-lg bg-primary-300 mb-4 cursor-pointer"
          // style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
        >
          <h3 className="text-grey font-DMSans text-[15px] text-white">
            Total Artisan
          </h3>
          <h3 className="text-primary font-DMSans font-extrabold text-[30px] mb-3 text-white">
            {dashboard?.num_of_artisan?.toLocaleString() || 0}
          </h3>
        </div>
      </div>
      <div className="w-full max-w-[100%]">
        <RecentTransaction data={dashboard} />
      </div>
    </AdminDashboardLayout>
  );
};

export default AdminDashboard;
