import React from "react";
import AdminDashboardLayout from "../layout/adminDashboardLayout";
import {
  Button,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { BsThreeDots } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import AddUser from "./addUser";
import EditUser from "./editUser";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getAllUsers,
  getArtisans,
  getChairman,
  updateUser,
} from "../../../api/user";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import { DelayFor, capitalize } from "../../../utils/helpers";
import { getShops } from "../../../api/shop";

const ManageUsers = () => {
  const [queryParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = React.useState<any>(null);
  const [page, setPage] = React.useState<number>(1);
  const [showUser, setShowUser] = React.useState<boolean>(false);
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const [id, setId] = React.useState<any>(null);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setAnchorEl(event.currentTarget);
    setId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getChairmanAndAdmin = () => {
    dispatch(SET_LOADER(true));
    getChairman(
      {
        page,
        name: /^[A-Za-z]+$/.test(search) ? search : "",
        phone_number: search,
      },
      (response) => {
        dispatch(SET_LOADER(false));
        setUsers(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  };

  const getAllArtisans = (active: boolean) => {
    dispatch(SET_LOADER(true));
    getArtisans(
      {
        page,
        is_active: active,
        name: /^[A-Za-z]+$/.test(search) ? search : "",
        phone_number: /^[0-9]+$/.test(search) ? search : "",
      },
      (response) => {
        dispatch(SET_LOADER(false));
        setUsers(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  };

  const getAllUser = (type: string) => {
    dispatch(SET_LOADER(true));
    getAllUsers(
      {
        page,
        type,
        name: /^[A-Za-z]+$/.test(search) ? search : "",
        phone_number: /^[0-9]+$/.test(search) ? search : "",
      },
      (response) => {
        dispatch(SET_LOADER(false));
        setUsers(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  };
  const getAllShops = () => {
    dispatch(SET_LOADER(true));
    getShops(
      {
        page,
        name: /^[A-Za-z]+$/.test(search) ? search : "",
        phone_number: /^[0-9]+$/.test(search) ? search : "",
      },
      (response) => {
        dispatch(SET_LOADER(false));
        console.log(response.data);
        setUsers({
          ...response.data,
          docs: response.data.docs?.map((item: any) => ({
            ...item.owner,
            shop: item,
          })),
        });
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  };

  React.useEffect(() => {
    const query = queryParams.get("query");
    console.log(query);
    if (query) {
      if (query === "chairman" || query === "artisan") {
        getAllUser(query);
      } else if (query === "shop") {
        getAllShops();
      } else if (query === "active_artisan") {
        getAllArtisans(true);
      } else {
        getAllUser("");
      }
    } else {
      getAllUser("");
    }
    // eslint-disable-next-line
  }, [page, queryParams.get("query"), search]);

  return (
    <AdminDashboardLayout className={`px-6 py-5 relative h-full`}>
      <AddUser
        show={showUser}
        setUsers={setUsers}
        hide={() => setShowUser(false)}
      />
      <EditUser
        show={showEdit}
        details={id}
        setUsers={setUsers}
        hide={() => setShowEdit(false)}
      />
      {/* TABLE MENU ITEM */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setShowEdit(true);
          }}
        >
          Edit
        </MenuItem>
        {id?.disabled ? (
          <MenuItem
            onClick={() => {
              handleClose();
              dispatch(SET_LOADER(true));
              updateUser(
                id?._id,
                { ...id, disabled: false },
                (response) => {
                  dispatch(SET_LOADER(false));
                  setUsers((prev: any) => {
                    const newData = prev.docs.map((item: any) =>
                      item._id === response.data?._id ? response.data : item
                    );
                    return {
                      ...prev,
                      docs: newData,
                    };
                  });
                  dispatch(
                    SET_MODAL({
                      isError: false,
                      message: response.message,
                      show: true,
                      title: "Success!",
                    })
                  );
                  DelayFor(() => {
                    dispatch(
                      SET_MODAL({
                        isError: false,
                        message: "",
                        show: false,
                        title: "",
                      })
                    );
                  }, 3000);
                },
                (error) => {
                  dispatch(SET_LOADER(false));
                  dispatch(
                    SET_MODAL({
                      isError: true,
                      message: error,
                      show: true,
                      title: "Error!",
                    })
                  );
                  DelayFor(() => {
                    dispatch(
                      SET_MODAL({
                        isError: false,
                        message: error,
                        show: false,
                        title: "",
                      })
                    );
                  }, 3000);
                }
              );
            }}
          >
            Activate
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              handleClose();
              dispatch(SET_LOADER(true));
              updateUser(
                id?._id,
                { ...id, disabled: true },
                (response) => {
                  dispatch(SET_LOADER(false));
                  setUsers((prev: any) => {
                    const newData = prev.docs.map((item: any) =>
                      item._id === response.data?._id ? response.data : item
                    );
                    return {
                      ...prev,
                      docs: newData,
                    };
                  });
                  dispatch(
                    SET_MODAL({
                      isError: false,
                      message: response.message,
                      show: true,
                      title: "Success!",
                    })
                  );
                  DelayFor(() => {
                    dispatch(
                      SET_MODAL({
                        isError: false,
                        message: "",
                        show: false,
                        title: "",
                      })
                    );
                  }, 3000);
                },
                (error) => {
                  dispatch(SET_LOADER(false));
                  dispatch(
                    SET_MODAL({
                      isError: true,
                      message: error,
                      show: true,
                      title: "Error!",
                    })
                  );
                  DelayFor(() => {
                    dispatch(
                      SET_MODAL({
                        isError: false,
                        message: error,
                        show: false,
                        title: "",
                      })
                    );
                  }, 3000);
                }
              );
            }}
          >
            Suspend
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            handleClose();
            navigate(id?._id, { state: id });
          }}
        >
          View
        </MenuItem>
      </Menu>
      <div className="w-full flex justify-between items-center mb-10">
        <p className="font-extrabold text-2xl">
          All{" "}
          {queryParams.get("query")
            ? queryParams.get("query") === "active_artisan"
              ? "Active Artisans"
              : capitalize(queryParams.get("query") || "")
            : "Users"}
        </p>
        <Button variant="contained" onClick={() => setShowUser(true)}>
          Add New User
        </Button>
      </div>
      <div className="w-full max-w-[100%]">
        <div className="w-full py-4 rounded-lg mb-2 h-full bg-white">
          <div className="px-6 w-full pb-3">
            <div className="md:w-[50%] w-[100%] rounded-lg overflow-hidden border flex items-center h-[35px]">
              <input
                type="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="w-[87%] px-3 py-2 text-[15px] h-full outline-none"
              />
              {/* <div
                onClick={() => {
                  dispatch(SET_LOADER(true));
                  getChairman(
                    {
                      page,
                      name: /^[A-Za-z]+$/.test(search) ? search : "",
                      phone_number: /^[0-9]+$/.test(search) ? search : "",
                    },
                    (response) => {
                      dispatch(SET_LOADER(false));
                      setUsers(response.data);
                    },
                    (error) => {
                      dispatch(SET_LOADER(false));
                      dispatch(
                        SET_MODAL({
                          isError: true,
                          message: error,
                          show: true,
                          title: "Error!",
                        })
                      );
                      DelayFor(() => {
                        dispatch(
                          SET_MODAL({
                            isError: false,
                            message: error,
                            show: false,
                            title: "",
                          })
                        );
                      }, 3000);
                    }
                  );
                }}
                className="w-[13%] h-full flex justify-center items-center bg-primary-300"
              >
                <BiSearch size={23} color="white" />
              </div> */}
            </div>
          </div>
          <div className="overflow-auto w-full max-w-full">
            <table className="table-auto min-w-[210%] lg:min-w-full">
              <thead className="sticky top-0">
                <tr className="bg-[#F4F6F8]">
                  <th
                    scope="col"
                    className="pl-6 pt-3 pb-2 text-left font-normal text-[#626262]"
                  >
                    Name
                  </th>
                  <th className="pt-3 pb-2 text-center font-normal text-[#626262]">
                    Phone
                  </th>
                  <th className="pt-3 pb-2 text-center font-normal text-[#626262]">
                    Gender
                  </th>
                  <th className="pt-3 pb-2 text-center font-normal text-[#626262]">
                    Role
                  </th>
                  <th className="pt-3 pb-2 text-center font-normal text-[#626262]">
                    Created By
                  </th>
                  <th className="pt-3 pb-2 text-center font-normal text-[#626262]">
                    Status
                  </th>
                  <th className="px-3 pt-3 pb-2 text-center font-normal text-[#626262]">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="w-full">
                {users?.docs.map((item: any, idx: number) => (
                  <tr
                    key={item._id}
                    className="border-b border-b-[rgba(145,158,171,0.24)]"
                  >
                    <th
                      scope="col"
                      className="py-3 text-left font-Grotesk font-normal text-black"
                    >
                      <p className="font-medium text-black ml-6">
                        {capitalize(item.first_name?.toLowerCase() || "")}{" "}
                        {capitalize(item.last_name?.toLowerCase() || "")}
                      </p>
                    </th>
                    <th className="py-5 text-center font-Grotesk font-normal text-black">
                      <p className="font-medium text-black">
                        {item?.phone_number}
                      </p>
                    </th>
                    <th className="py-5 text-center font-Grotesk font-normal text-black">
                      <p className="font-medium text-black">
                        {capitalize(item?.gender || "")}
                      </p>
                    </th>
                    <th className="py-5 text-center font-Grotesk font-normal text-[#637381]">
                      <p className="font-medium text-black">
                        {item?.shop && item?._id === item?.shop?.owner?._id
                          ? "Shop Owner"
                          : capitalize(item?.type || "")}
                      </p>
                    </th>
                    <th className="py-5 text-center font-Grotesk font-normal text-[#637381]">
                      <p className="font-medium text-black">
                        {item.created_by
                          ? item?.created_by
                              ?.split(" ")
                              ?.map((data?: string) =>
                                data ? capitalize(data) : ""
                              )
                              ?.join(" ")
                          : "N/A"}
                      </p>
                    </th>
                    <th className="py-5 text-center font-Grotesk font-normal">
                      <p
                        className={`font-medium ${
                          item.disabled ? "text-[#E25C5C]" : "text-[#53D258]"
                        }`}
                      >
                        {item.disabled ? "Disabled" : "Active"}
                      </p>
                    </th>
                    {item.type !== "artisan" ? (
                      <th className="py-5 text-center font-Grotesk text-black font-medium flex justify-center items-center">
                        <button onClick={(e) => handleClick(e, item)}>
                          <BsThreeDots size={25} className="cursor-pointer" />
                        </button>
                      </th>
                    ) : item.type === "artisan" &&
                      queryParams.get("query") === "shop" ? (
                      <th className="py-5 text-center font-Grotesk text-black font-medium flex justify-center items-center">
                        <button
                          onClick={(e) => navigate(item?._id, { state: item })}
                        >
                          <BsThreeDots size={25} className="cursor-pointer" />
                        </button>
                      </th>
                    ) : (
                      <th className="py-5 text-center font-Grotesk text-black font-medium flex justify-center items-center">
                        <button
                          onClick={(e) => navigate(item?._id, { state: item })}
                        >
                          <BsThreeDots size={25} className="cursor-pointer" />
                        </button>
                      </th>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="w-full px-6 flex justify-end items-end mt-3 ">
            <Pagination
              count={users?.totalPages || 0}
              page={page}
              // color="secondary"
              onChange={(event, value: number) => setPage(value)}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: HiArrowLongLeft,
                    next: HiArrowLongRight,
                  }}
                  {...item}
                />
              )}
            />
          </div>
        </div>
      </div>
    </AdminDashboardLayout>
  );
};

export default ManageUsers;
