import React from "react";
import DashboardLayout from "../layout/dashboardLayout";
import { Button, Pagination, PaginationItem } from "@mui/material";
import Timer, { ExpiredNotice } from "../../../components/timer";
import { useReactToPrint } from "react-to-print";
import { useDispatch } from "react-redux";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import { Link, useParams } from "react-router-dom";
import { getArtisans, getShopOwners, getUserByID } from "../../../api/user";
import {
  DelayFor,
  capitalize,
  isSubscriptionExpired,
} from "../../../utils/helpers";
import PaymentCard from "./paymentCard";
import ArtisanCard from "../../../components/artisanCard";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import moment from "moment";

const Detail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [payOpen, setPayOpen] = React.useState<boolean>(false);
  const [details, setDetails] = React.useState<any>(null);
  const [artisans, setArtisans] = React.useState<any>(null);
  const [page, setPage] = React.useState<number>(1);

  const componentRef = React.useRef<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Artisan ID Card",
  });

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    getUserByID(
      params?.id || "",
      (response) => {
        dispatch(SET_LOADER(false));
        setDetails(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  }, [params?.id]);

  React.useEffect(() => {
    dispatch(SET_LOADER(true));
    if (details) {
      getArtisans(
        { page, shop: details?.shop?._id },
        (res) => {
          dispatch(SET_LOADER(false));
          setArtisans(res.data);
        },
        (error) => {
          dispatch(SET_LOADER(false));
          dispatch(
            SET_MODAL({
              isError: true,
              message: error,
              show: true,
              title: "Error!",
            })
          );
          DelayFor(() => {
            dispatch(
              SET_MODAL({
                isError: false,
                message: error,
                show: false,
                title: "",
              })
            );
          }, 3000);
        }
      );
    }
  }, [details, page]);

  console.log(details);

  return (
    <DashboardLayout className="py-6 px-6">
      <>
        <div className="hidden">
          <div
            ref={componentRef}
            className="max-w-[300px] shadow-lg my-2 py-4 px-3 bg-white mx-auto rounded-lg"
          >
            <img
              src={
                details?.image?.url ||
                "https://images.unsplash.com/photo-1581578017306-7334b15283df?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGdhcmRlbmluZ3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60"
              }
              alt=""
              className="w-full h-[170px] object-cover mb-3"
            />
            <h2 className="text-center text-2xl font-bold mb-2">
              {capitalize(details?.first_name || "")}{" "}
              {capitalize(details?.last_name || "")}
            </h2>
            <p className="text-center text-xl font-bold text-primary-300 mb-2">
              {capitalize(details?.service?.name || "")}
            </p>
            <p className="text-center text-md">
              Area Unit: {details?.area_unit?.name}
            </p>
            <p className="text-center text-md">
              Shop Address: {details?.shop?.owner?.address}
            </p>
            <p className="text-center text-md">
              LGA: {details?.area_unit?.lga?.name}
            </p>
            <p className="text-center text-md">
              Issuing Date: {moment(new Date()).format("DD/MM/YYYY")}
            </p>
            <p className="text-center text-md">
              Expiry Date:{" "}
              {moment(new Date()).add("1", "year").format("DD/MM/YYYY")}
            </p>
          </div>
        </div>
        <div className="flex md:flex-row flex-col items-center justify-between">
          <div>
            <h1 className=" text-2xl text-center md:text-left font-bold text-primary-300">
              {capitalize(details?.first_name || "") +
                " " +
                capitalize(details?.last_name || "")}
              {details && "'s Profile"}
            </h1>
          </div>
          <div>
            {details?.sub_exipry_date ? (
              <Timer targetDate={details?.sub_exipry_date} />
            ) : (
              <ExpiredNotice />
            )}
          </div>
          <div className=" w-1/2 md:w-1/5">
            <Button
              variant="contained"
              fullWidth
              disabled={
                details?.sub_exipry_date
                  ? isSubscriptionExpired(details?.sub_exipry_date)
                  : true
              }
              sx={{
                background: "#EFEDF2",
                color: "#532B81",
                border: "1px solid #532B81",
                padding: "10px 0",
                ":hover": { bgcolor: "#532B81", color: "white" },
              }}
              type="submit"
              onClick={handlePrint}
            >
              Print ID card
            </Button>
          </div>
        </div>

        <div className="lg:flex mt-10">
          <div className="w-full lg:w-1/5 mb-10 lg:mb-0">
            <div className=" bg-grey-200 w-36 h-36 rounded-full shadow mx-auto md:mx-0">
              <img
                src={
                  details?.image?.url ||
                  "https://images.unsplash.com/photo-1581578017306-7334b15283df?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGdhcmRlbmluZ3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60"
                }
                alt=""
                className="w-full h-full rounded-full"
              />
            </div>
          </div>
          <div className="w-full lg:w-4/5">
            <form action="" className="grid md:grid-cols-2 gap-8">
              <div>
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  placeholder={"Felix"}
                  value={capitalize(details?.first_name || "")}
                  className="w-full text-sm bg-white placeholder:text-grey-50 placeholder:font-light py-3 px-3 mt-1 focus:outline-none"
                />
              </div>
              <div>
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  placeholder="Joe"
                  value={capitalize(details?.last_name || "")}
                  className="w-full text-sm bg-white placeholder:text-grey-50 placeholder:font-light py-3 px-3 mt-1 focus:outline-none"
                />
              </div>
              <div>
                <label htmlFor="">Phone Number</label>
                <input
                  type="text"
                  placeholder="08127451653"
                  value={capitalize(details?.phone_number || "")}
                  className="w-full text-sm bg-white placeholder:text-grey-50 placeholder:font-light py-3 px-3 mt-1 focus:outline-none"
                />
              </div>
              <div>
                <label htmlFor="">Shop Address</label>
                <input
                  type="text"
                  placeholder="KM 100 old aba road waterlines"
                  value={capitalize(details?.address || "")}
                  className="w-full text-sm bg-white placeholder:text-grey-50 placeholder:font-light py-3 px-3 mt-1 focus:outline-none"
                />
              </div>
              <div>
                <label htmlFor="">CAC (Optional)</label>
                <input
                  type="text"
                  placeholder="CAC"
                  value={capitalize(details?.cac || "")}
                  className="w-full text-sm bg-white placeholder:text-grey-50 placeholder:font-light py-3 px-3 mt-1 focus:outline-none"
                />
              </div>
              <div>
                <label htmlFor="">Type of Service</label>
                <input
                  type="text"
                  placeholder="Plumber"
                  value={capitalize(details?.service?.name || "")}
                  className="w-full text-sm bg-white placeholder:text-grey-50 placeholder:font-light py-3 px-3 mt-1 focus:outline-none"
                />
              </div>
              {/* <div>
                <Button
                  variant="contained"
                  sx={{
                    background: "#532B81",
                    color: "#fff",
                    padding: "10px 0",
                    ":hover": { bgcolor: "#532B81", color: "white" },
                  }}
                  onClick={(e) => setPayOpen(true)}
                >
                  Pay
                </Button>
              </div> */}
            </form>
          </div>
        </div>
        <PaymentCard payOpen={payOpen} setPayOpen={setPayOpen} />

        {details?.shop?.owner?._id === details?._id && (
          <div className="flex justify-between flex-row flex-wrap mt-10 mx-6">
            {artisans?.docs.map((item: any) => (
              <div
                key={item._id}
                className="mb-5 w-[48%] md:w-[32%] lg:w-[24%]"
              >
                <Link to={`/chairman/dashboard/artisans/${item._id}`}>
                  <ArtisanCard detail={item} />
                </Link>
              </div>
            ))}
            <div className="w-full px-6 flex justify-end items-end mt-3 ">
              <Pagination
                count={artisans?.totalPages || 0}
                page={page}
                // color="secondary"
                onChange={(event, value: number) => setPage(value)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{
                      previous: HiArrowLongLeft,
                      next: HiArrowLongRight,
                    }}
                    {...item}
                  />
                )}
              />
            </div>
          </div>
        )}
      </>
    </DashboardLayout>
  );
};

export default Detail;
