import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface Root {
  artisan: Artisan;
  response: any;
}

interface Artisan {
  firstName: string;
  lastName: string;
  gender: string;
  address: string;
  cac: string;
  service: string;
  phoneNumber: string;
  image: File | null
}

const initialState: Root = {
  artisan: {
    firstName: "",
    lastName: "",
    gender: "",
    phoneNumber: "",
    address: "",
    cac: "",
    service: "",
    image: null
  },
  response: null,
};

export const artisanSlice = createSlice({
  name: "artisan",
  initialState,
  reducers: {
    SET_ARTISAN: (state, action: PayloadAction<Artisan | null>) => {
      state.artisan = action.payload
        ? action.payload
        : {
            firstName: "",
            lastName: "",
            gender: "",
            phoneNumber: "",
            address: "",
            cac: "",
            service: "",
            image: null
          };
    },
    SET_RESPONSE: (state, action: PayloadAction<any>) => {
      state.response = action.payload;
    },
  },
});

export const { SET_ARTISAN, SET_RESPONSE } = artisanSlice.actions;

export default artisanSlice.reducer;
