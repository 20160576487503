import React from "react";
import AdminDashboardLayout from "../layout/adminDashboardLayout";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_LOADER, SET_MODAL } from "../../../store/appSlice";
import { DelayFor, capitalize } from "../../../utils/helpers";
import { Pagination, PaginationItem } from "@mui/material";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { getShops } from "../../../api/shop";
import ShopOwnerCard from "../../../components/shopOwnerCard";
import ArtisanCard from "../../../components/artisanCard";
import { getArtisans } from "../../../api/user";

const ViewUser = () => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(1);
  const [artisans, setArtisans] = React.useState<any>(null);
  const location = useLocation();

  const getAllArtisan = () => {
    dispatch(SET_LOADER(true));
    getArtisans(
      { page, shop: location?.state?.shop?._id },
      (response) => {
        dispatch(SET_LOADER(false));
        setArtisans(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  };
  const getAllShops = () => {
    dispatch(SET_LOADER(true));
    getShops(
      { page, area_unit: location?.state?.area_unit?._id },
      (response) => {
        dispatch(SET_LOADER(false));
        setArtisans(response.data);
      },
      (error) => {
        dispatch(SET_LOADER(false));
        dispatch(
          SET_MODAL({
            isError: true,
            message: error,
            show: true,
            title: "Error!",
          })
        );
        DelayFor(() => {
          dispatch(
            SET_MODAL({
              isError: false,
              message: error,
              show: false,
              title: "",
            })
          );
        }, 3000);
      }
    );
  };

  React.useEffect(() => {
    console.log(location.state);
    if (location?.state?.type === "artisan") {
      getAllArtisan();
    } else {
      getAllShops();
    }
  }, [location?.state]);
  return (
    <AdminDashboardLayout className="py-6 px-6">
      <>
        <div className="flex md:flex-row flex-col items-center justify-between">
          <div>
            <h1 className=" text-2xl text-center md:text-left font-bold text-primary-300">
              {location.state?.first_name + " " + location.state?.last_name}
              's Profile
            </h1>
          </div>
        </div>

        <div className="lg:flex mt-10">
          <div className="w-full lg:w-4/5">
            <form action="" className="grid md:grid-cols-2 gap-8">
              <div>
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  placeholder={"Felix"}
                  disabled
                  value={location.state?.first_name}
                  className="w-full text-sm bg-white placeholder:text-grey-50 placeholder:font-light py-3 px-3 mt-1 focus:outline-none"
                />
              </div>
              <div>
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  placeholder="Joe"
                  value={location.state?.last_name}
                  disabled
                  className="w-full text-sm bg-white placeholder:text-grey-50 placeholder:font-light py-3 px-3 mt-1 focus:outline-none"
                />
              </div>
              <div>
                <label htmlFor="">Phone Number</label>
                <input
                  type="text"
                  placeholder="08127451653"
                  value={location.state?.phone_number}
                  disabled
                  className="w-full text-sm bg-white placeholder:text-grey-50 placeholder:font-light py-3 px-3 mt-1 focus:outline-none"
                />
              </div>
              <div>
                <label htmlFor="">Gender</label>
                <input
                  type="text"
                  placeholder="Gender"
                  disabled
                  value={location.state?.gender}
                  className="w-full text-sm bg-white placeholder:text-grey-50 placeholder:font-light py-3 px-3 mt-1 focus:outline-none"
                />
              </div>
              <div>
                <label htmlFor="">Role</label>
                <input
                  type="text"
                  placeholder="Chairman"
                  disabled
                  value={
                    location.state?.shop &&
                    location.state?._id === location.state?.shop?.owner?._id
                      ? "Shop Owner"
                      : capitalize(location.state?.type || "")
                  }
                  className="w-full text-sm bg-white placeholder:text-grey-50 placeholder:font-light py-3 px-3 mt-1 focus:outline-none"
                />
              </div>
              {location.state?.area_unit && (
                <>
                  <div>
                    <label htmlFor="">LGA</label>
                    <input
                      type="text"
                      placeholder="LGA"
                      disabled
                      value={location.state?.area_unit?.lga?.name}
                      className="w-full text-sm bg-white placeholder:text-grey-50 placeholder:font-light py-3 px-3 mt-1 focus:outline-none"
                    />
                  </div>
                  <div>
                    <label htmlFor="">Area Unit</label>
                    <input
                      type="text"
                      placeholder="Rumuola"
                      value={location.state?.area_unit?.name}
                      className="w-full text-sm bg-white placeholder:text-grey-50 placeholder:font-light py-3 px-3 mt-1 focus:outline-none"
                    />
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
        {location.state?.area_unit && (
          <>
            {location.state?.type === "artisan" &&
            location.state?.shop?.owner?._id !== location.state?._id ? null : (
              <>
                <div className="flex md:flex-row flex-col items-center justify-between my-4">
                  <div>
                    <h1 className=" text-2xl text-center md:text-left font-bold text-primary-300">
                      {location.state?.type !== "artisan"
                        ? capitalize(location.state?.area_unit?.name || "")
                        : capitalize(location.state?.shop?.name || "")}{" "}
                      {location?.state?.type !== "artisan"
                        ? "Shops"
                        : "Artisans"}
                    </h1>
                  </div>
                  {/* <div><Timer targetDate="2022-11-27T00:10:45+00:00" /></div> */}
                </div>
                <div className="flex justify-between flex-row flex-wrap">
                  {artisans?.docs?.map((item: any) => (
                    <>
                      <div
                        key={item._id}
                        className="mb-5 w-[48%] md:w-[32%] lg:w-[24%]"
                      >
                        {location.state?.type !== "artisan" ? (
                          <ShopOwnerCard detail={item} />
                        ) : (
                          <ArtisanCard detail={item} />
                        )}
                      </div>
                    </>
                  ))}
                </div>
                <div className="w-full px-6 flex justify-end items-end mt-3 ">
                  <Pagination
                    count={artisans?.totalPages || 0}
                    page={page}
                    // color="secondary"
                    onChange={(event, value: number) => setPage(value)}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: HiArrowLongLeft,
                          next: HiArrowLongRight,
                        }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </>
            )}
          </>
        )}
      </>
    </AdminDashboardLayout>
  );
};

export default ViewUser;
